import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { IconButton, Tooltip } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import EditableList from '../../containers/EditableList';
import { EDITMODAL_MODES } from '../../containers/EditableList/constants';
import EditModal from './EditModal';
import Confirm from '../../components/base/Confirm';
import Notify from '../../components/base/Notify';
import Ajax from '../../utils/Ajax';
import styles from './styles.js';
class Event extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      duplicateEvent: null,
      openConfirmDuplicate: false,
      refreshToken: 0,
    };
  }

  async onDuplicate() {
    await new Promise(resolve => this.setState({ openConfirmDuplicate: false }, resolve));
    const { duplicateEvent } = this.state;
    let res = await Ajax.post({
      url: 'api/event-clone/',
      token: true,
      body: { id: duplicateEvent.id }
    });
    if (res.type === 'success') {
      this.setState(prev => ({ duplicateEvent: null, refreshToken: prev.refreshToken + 1 }));
      Notify.success('Évènement dupliqué avec succès');
    } else {
      Notify.error(res.message);
      this.setState({ duplicateEvent: null });
    }
  }

  renderConfirm() {
    const { openConfirmDuplicate } = this.state;
    return (
      <Confirm 
        open={openConfirmDuplicate}
        title="Dupliquer un évènement"
        message="Êtes-vous sûr de vouloir dupliquer cet évènement ?"
        onClickAway={() => this.setState({ duplicateEvent: null, openConfirmDuplicate: false })}
        onValidate={() => this.onDuplicate()}
      />
    );
  }

  render() {
    const { classes, user, shop } = this.props;
    const { duplicateEvent, refreshToken } = this.state;
    return (
      <div className={classes.wrapper}>
        {this.renderConfirm()}
        <EditableList
          refreshToken={refreshToken}
          title="Évènements"
          route="events"
          rowKey="id"
          defaultParams={{ closed: '0', order: 'event_date', shop_id: shop.id }}
          maxSize="xl"
          columns={[
            {
              field: '__view', headerName: '', width: 100, sortable: false, renderCell: ({row }) => (
                <>
                  <Tooltip title="Modifier les dotations et les participants">
                    <Link to={`event/view/${row.id}`}>
                      <IconButton>
                        <SettingsIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                  <Tooltip title="Dupliquer l'évènement">
                    <span>        
                      <IconButton onClick={() => this.setState({ duplicateEvent: row, openConfirmDuplicate:true })} disabled={!!duplicateEvent}>
                        <ContentCopyIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </>
              )
            },
            { field: 'id', headerName: 'ID', width: 90 },
            { field: 'closed', headerName: 'Cloturé', width: 90, valueFormatter: ({ value }) => value === 1 ? 'Oui' : 'Non' },
            { field: 'name', headerName: 'Nom', width: 200 },
            { field: 'price', headerName: 'Prix', width: 100 },
            { field: 'shop_id', headerName: 'Boutique', width: 90, sortable: false, valueFormatter: ({ value }) => user.shops.find(shop => parseInt(shop.id, 10) === value)?.name || '' },
            { field: 'event_date', headerName: 'Date', width: 150, valueFormatter: ({ value }) => moment(value).format("DD-MM-YYYY") },
            { field: 'event_hour', headerName: 'Heures', width: 90, sortable: false },
            { field: 'visibility', headerName: 'Visible', width: 90, valueFormatter: ({ value }) => value === 1 ? 'Oui' : 'Non' },
            { field: 'quantity', headerName: 'Places', width: 90  },
            { field: 'reserved_quantity', headerName: 'Places payées', width: 130, sortable: false  },
            { field: 'taxonomy_value_name', headerName: 'Gamme', width: 200, sortable: false },
            { field: 'format_name', headerName: 'Format', width: 90, sortable: false },
            { field: 'registration_name', headerName: 'Immatriculation', width: 150, sortable: false },
            { field: 'created_date', headerName: 'Date de création', width: 180, valueFormatter: ({ value }) => moment.utc(value, "YYYY-MM-DD HH:mm:ss").local().format("YYYY-MM-DD HH:mm:ss") },
            { field: 'updated_date', headerName: 'Date de mise à jour', width: 180, valueFormatter: ({ value }) => moment.utc(value, "YYYY-MM-DD HH:mm:ss").local().format("YYYY-MM-DD HH:mm:ss") },
          ]}
          filterParams={[
            { key: 'id', label: 'ID' },
            { key: 'name', label: 'Nom' },
            { key: 'shop_id', label: 'Boutiques', type: 'select', options: user.shops.map(item => parseInt(item.id, 10)), getOptionLabel: opt => user.shops.find(shop => parseInt(shop.id, 10) === opt)?.name || '', variant: 'standard' },
            { key: 'visibility', label: 'Visible', type: 'select', options: ['', '0', '1'], getOptionLabel: opt => {
              if(opt === '1') {
                return 'Oui';
              } else if(opt === '0') {
                return 'Non';
              }
              return 'Tout';
            }, variant: 'standard' },
            { key: 'closed', label: 'Évènements clôturés', type: 'select', options: ['', '0', '1'], getOptionLabel: opt => {
              if(opt === '1') {
                return 'Oui';
              } else if(opt === '0') {
                return 'Non';
              }
              return 'Tout';
            }, variant: 'standard' },
          ]}
          requiredFields={{
            [EDITMODAL_MODES.ADD]: ['name', 'event_date', 'shop_id'],
            [EDITMODAL_MODES.EDIT]: ['name', 'event_date','shop_id']
          }}
          EditModalComponent={EditModal}
          searchPlaceholder="Filter par Nom, description et format description"
          options={{
            shops: user.shops,
          }}
        />
      </div>
    );
  }
}

Event.propTypes = {
  classes: PropTypes.object.isRequired,
  redirect: PropTypes.func.isRequired,
  match: PropTypes.object,
  user: PropTypes.object,
}

export default withStyles(styles)(Event);

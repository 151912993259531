import React from 'react';
import { withStyles } from '@mui/styles';
import Stack from '@mui/material/Stack';
import EditModalWrapper from '../../../containers/EditableList/EditModalWrapper';
// import { EDITMODAL_MODES } from '../../../containers/EditableList/constants';
import Ajax from '../../../utils/Ajax';
import Notify from '../../../components/base/Notify';
import _debounce from 'lodash/debounce';
import styles from './styles';
import { isEmpty } from 'lodash';

class EditModal extends EditModalWrapper {

  constructor(props) {
    super(props);
    this.getTaxoValueOptionsLabel = this.getTaxoValueOptionsLabel.bind(this);
  }

  extendState() {
    return { loadingTaxonomy: false, taxonomy_value: [] };
  }

  async componentDidMount() {
    const listTaxonomyValue = await this.getTaxonomyValueSearch('');

    this.setState({ taxonomy_value: listTaxonomyValue });
  }

  async getTaxonomyValueSearch(value) {
    this.setState({ loadingTaxonomy: true });
    const res = await Ajax.get(
      {
        url: 'api/gamme-autocomplete',
        queryString: {
          search: value,
        },
        token: true,
      }
    );
    this.setState({ loadingTaxonomy: false });
    if (res.type === "success") {
      return res.list;
    } else {
      Notify.error(`Aucune gammes trouvées.`);
    }
    return [];
  }

  getTaxoValueOptions() {
    const { taxonomy_value, form } = this.state;
    const result = taxonomy_value.map(tv => tv.id);
    if (form && form.event_id && !result.find(id => id === form.taxonomy_value_id)) {
      result.unshift(form.taxonomy_value_id);
    }
    return result;
  }

  getTaxoValueOptionsLabel(tvId) {
    const { taxonomy_value, form } = this.state;
    if (form && form.taxonomy_value_id && form.taxonomy_value_id === tvId) {
      return `${form.taxonomy_value_name}`;
    }
    const findOption = taxonomy_value.find(e => e.id === tvId);
    if (findOption) {
      return `${findOption.name}`;
    }
    return '';
  }

  handleChangeSelect(name, value) {
    if (name) {
      this.setState(prev => {
        const form = { ...prev.form };
        if (name === 'taxonomy_value_id') {
          const { taxonomy_value } = this.state;
          const tv = value ? taxonomy_value.find(p => p.id === value) : null;
          form.taxonomy_value_name = tv ? tv.name : '';
        }
        form[name] = value;
        return { form, missingFields: [], invalidFields: [] };
      });
    }
  }

  renderContent() {
    const { item } = this.props;
    const { loadingTaxonomy } = this.state;

    return (
      <Stack
        direction="column"
        spacing={2}
      >
        <Stack direction="row" spacing={2}>
          {!isEmpty(item) && this.renderTextField({ key: 'id', label: 'ID', disabled: true })}
          {this.renderTextField({ key: 'name', label: 'Nom' })}
        </Stack>
        {this.renderSelect({
          key: 'taxonomy_value_id',
          label: 'Gamme',
          placeholder: 'Gamme',
          options: this.getTaxoValueOptions(),
          multiple: false,
          loading: loadingTaxonomy,
          helperText: 'Choisir dans la liste ou tapez le terme de recherche',
          getOptionLabel: this.getTaxoValueOptionsLabel,
          onInputChange: _debounce(async evt => {
            const { value } = evt.target;
            const list = await this.getTaxonomyValueSearch(value);
            this.setState({ taxonomy_value: list });
          }, 300),
          filterOptions: options => options
        })}
      </Stack>
    );
  }
}

export default withStyles(styles)(EditModal);

import React from 'react';
import { matchPath, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import forEach from 'lodash/forEach';
import isNil from 'lodash/isNil';

import Notify from '../../components/base/Notify';
import App from '../App';
import PageList, { getPage } from './path';
import Config from '../../config';

class Router extends React.Component {

  constructor(props) {
    super(props);
    this.page = null;
    this.match = null;
    this.pathname = props.location.pathname;
    this.redirect = this.redirect.bind(this);
  }

  redirect(pathname) {
    if (pathname !== this.pathname) {
      this.props.history.push(pathname);
    }
  }

  /** ROUTE */
  getRoute(pathname) {
    let match = null;
    let page = getPage('NotFound');
    forEach(PageList, item => {
      if (!isNil(item.route)) {
        match = matchPath(pathname, {
          path: item.route,
          strict: false,
          exact: item.exact
        });
        if (!isNil(match)) {
          page = item;
          return false;
        }
      }
      return true;
    });
    return { page, match };
  }

  /** RENDER */
  shouldComponentUpdate(nextProps) {
    const { user, location } = nextProps;
    if (!user) {
      return false;
    }
    
    const { UNLOGGED_REDIRECTION, LOGGED_REDIRECTION } = Config;
    const { page, match } = this.getRoute(location.pathname);
    const { action } = page;
    if (action) {
      if (!user.id) {
        // il faut se connecter
        Notify.info('Vous êtes déconnecté');
        this.redirect(UNLOGGED_REDIRECTION);
        return false;
      }
      if (!user.actions || !user.actions[action]) {
        // autorisation refusée
        Notify.error('Accès refusé');
        this.redirect(UNLOGGED_REDIRECTION);
        return false;
      }
    }
    if (user.id !== '' && page.route === UNLOGGED_REDIRECTION) {
      // redirection de connexion auto
      this.redirect(LOGGED_REDIRECTION);
      return false;
    }
    this.pathname = location.pathname;
    this.page = page;
    this.match = match;
    return true;
  }

  render() {
    return (
      <App
        {...this.props}
        match={this.match}
        page={this.page}
        redirect={this.redirect}
      />
    );
  }
}

Router.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  user: PropTypes.object,
  onUser: PropTypes.func,
}

export default withRouter(Router);
const style = () => ({
  eventModal: {
    '& .quillOverride .ql-editor': {
      minHeight: 300,
    },
  },
});


export default style;

//ql-editor
const theme = (theme) => ({
  input:{
    position: 'relative',
    backgroundRepeat: 'no-repeat',
  },
  inputLabel: {
    width: '100%',
    height: '100%',
  },
  inputButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  inputButtonDisabled: {
    '&:after': {
      display: 'block',
      content: '',
      position: 'absolute',
      top:0,
      left: 0,
      width: '100%',
      height: '100%',
      opacity: 0.3,
      zIndex: 3,
      backgroundColor: '#ffffff',
    }
  },
  imageItem: {
    height: '80px',
    width: '80px',
    objectFit: 'cover',
  },
});

export default theme;

import React from 'react';
import { Prompt as RouterPrompt } from 'react-router-dom';
import PropTypes from 'prop-types';
import uniqueId from 'lodash/uniqueId'
import isNil from 'lodash/isNil'

import Confirm from '../Confirm';

// reference des prompts en cours
const References = {};

class Prompt extends React.PureComponent {
  static getUserConfirmation(ref, callback) {
    const current = References[ref];
    current.callPrompt(callback);
  }

  static defaultProps = {
    title: 'Demande de confirmation',
    message: 'Êtes vous sûr de vouloir quitter la page en cours ?',
    cancel: 'Rester',
    validate: 'Quitter la page',
  }

  /** SETUP */
  constructor(props) {
    super(props);
    // refs
    this.reference = uniqueId('Prompt-');
    References[this.reference] = this;
    this.callback = null;
    // handlers
    this.callPrompt = this.callPrompt.bind(this);
    this.onClickAway = this.onClickAway.bind(this);
    this.onValidate = this.onValidate.bind(this);
    // state
    this.state = {
      open: false
    }
  }

  componentWillUnmount() {
    delete References[this.reference];
  }
  /** CATCH REFERENCE */
  callPrompt(callback) {
    this.callback = callback;
    this.setState({ open: true });
  }

  /** HANDLERS */
  onClickAway() {
    this.setState({ open: false });
    if (!isNil(this.props.onCancel)) {
      this.props.onCancel(() => this.callback(false));
    } else {
      this.callback(false);
    }
  }
  onValidate() {
    this.setState({ open: false });
    if (!isNil(this.props.onValidate)) {
      this.props.onValidate(() => this.callback(true));
    } else {
      this.callback(true);
    }
  }
  /** RENDER */
  render() {
    const { open } = this.state;
    const { when, title, message, cancel, validate } = this.props;
    return (
      <React.Fragment>
        <RouterPrompt when={when} message={this.reference} />
        <Confirm
          open={open}
          onClickAway={this.onClickAway}
          onValidate={this.onValidate}
          title={title}
          message={message}
          cancel={cancel}
          validate={validate}
        />
      </React.Fragment>
    );
  }

}

Prompt.propTypes = {
  when: PropTypes.bool.isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
  cancel: PropTypes.string,
  validate: PropTypes.string,
  onValidate: PropTypes.func,
  onCancel: PropTypes.func,
}

export default Prompt;
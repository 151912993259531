const mimeTypeMap = {
  'CSV': 'text/csv;charset=utf-8;',
  'JSON': 'application/json;charset=utf-8;'
}

export default function Download(data, filename, type) {
  let a = document.createElement("a");
  const mimeType = mimeTypeMap[type] || null;
  let file = new Blob([data], { type: mimeType });
  if (window.navigator.msSaveOrOpenBlob) // IE10+
    window.navigator.msSaveOrOpenBlob(file, filename);
  else { // Others
    let url = URL.createObjectURL(file);
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
};

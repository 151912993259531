const styles = {
  wrapper: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
  },
  content: {
    minWidth: 256,
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
  },
  logo: {
    width: 240, 
  },
};

export default styles;

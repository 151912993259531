const theme = (theme) => ({
  wrapper: {
    height: 'calc(100% - 25px)',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 0,
  },
  ariane: {
    color: theme.palette.black.light,
    marginBottom: `${theme.spacing(1)} !important`,
    display: 'block',
    flexGrow: 0,
  },
  titleContainer: {
    marginBottom: theme.spacing(2),
  },
  formContent: {
    padding: theme.spacing(4),
  },
});

export default theme;
const styles = {
  wrapper: {
    height: 'calc(100vh - 90px)',
    boxSizing: 'border-box',
    paddingRight: 72, 
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    textAlign: 'center',
  },
  redirectButton: {
    marginTop: '32px !important',
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
    }
  },
};

export default styles;

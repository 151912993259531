import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import styles from './styles.js';
import EditableList from '../../containers/EditableList';
import EditModal from './EditModal';
import { EDITMODAL_MODES } from '../../containers/EditableList/constants';

class Registration extends React.Component {

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.wrapper}>
        <EditableList
          title="Immatriculations"
          route="registrations"
          rowKey="id"
          searchKey="name"
          columns={[
            { field: 'id', headerName: 'ID', width: 90 },
            { field: 'name', headerName: 'Nom', width: 200 },
            { field: 'taxonomy_value_name', headerName: 'Gamme', width: 200 },
          ]}
          filterParams={[
            { key: 'id', label: 'ID' },
            { key: 'taxonomy_value_name', label: 'Gamme' },
          ]}
          requiredFields={{
            [EDITMODAL_MODES.ADD]: ['name', 'taxonomy_value_id'],
            [EDITMODAL_MODES.EDIT]: ['name', 'taxonomy_value_id']
          }}
          EditModalComponent={EditModal}
          searchPlaceholder="Filter par Nom"
          // allowEdit={rights.includes('write')}
          // allowAdd={rights.includes('write')}
          // allowDelete={rights.includes('delete')}
        />
      </div>
    );
  }
}

Registration.propTypes = {
  classes: PropTypes.object.isRequired,
  redirect: PropTypes.func.isRequired,
  match: PropTypes.object,
  user: PropTypes.object,
}

export default withStyles(styles)(Registration);

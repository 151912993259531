const theme = (theme) => ({
  wrapper: {
    position: 'relative',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 0,
  },
  imageListWrapper: {
    position: 'relative',
    height: `calc(100% - 250px - ${theme.spacing(6)})`,
    display: "flex",
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
    padding: `${theme.spacing(2)} 0`,
    overflow: 'auto',
  },
  imageContainer: {
    position: 'relative',
    width: '240px',
    height: '300px',
  },
  imageItem: {
    width: '240px',
    height: '300px',
    objectFit: 'cover',
  },
  deleteBtn: {
    position: 'absolute !important',
    color: `${theme.palette.red.main} !important`,
    backgroundColor: 'rgba(200, 200, 200, 0.4) !important',
    right: theme.spacing(1),
    top: theme.spacing(1),
    zIndex: 5,
    '&:hover': {
      color: `rgba(200, 200, 200) !important`,
      backgroundColor: 'rgba(214, 64, 69, 1) !important',
      border: '1px solid #ededed'
    }
  }
});

export default theme;
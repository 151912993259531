import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { withStyles } from "@mui/styles";
import {
	Box,
	Button,
	Paper,
	Tab,
	Tabs,
	Typography,
	CircularProgress,
	Tooltip,
} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";

import Ajax from "../../../utils/Ajax/index.js";
import Notify from "../../../components/base/Notify/index.js";
import Endowment from "../../Endowment/index.js";
import Participant from "../../Participant/index.js";
import Grid from "../../../components/base/Grid";
import Confirm from "../../../components/base/Confirm";
import styles from "./styles.js";
class EventView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			event: null,
			notFound: false,
			tab: 0,
			loadingClose: false,
			openClose: false,
		};
		this.handleChangeTab = this.handleChangeTab.bind(this);
		this.refreshEvent = this.refreshEvent.bind(this);
	}

	async componentDidMount() {
		const { params } = this.props.match;
		const id = params.id ? params.id : "";
		const event = await this.getEvent(id);
		this.setState({ event: event || null, notFound: !event });
	}

	async getEvent(id) {
		const res = await Ajax.get({
			url: "api/events/" + id,
			token: true,
		});
		if (res.type === "success") {
			return res.item;
		} else {
			Notify.error(`Aucun évènement trouvé.`);
			return false;
		}
	}

	async refreshEvent() {
		if (this.state.event) {
			const event = await this.getEvent(this.state.event.id);
			this.setState({ event });
		}
	}

	handleChangeTab = (event, tab) => {
		this.setState({ tab });
	};

	async onClose() {
		await new Promise((resolve) =>
			this.setState({ openClose: false }, resolve)
		);
		const { event } = this.state;
		let res = await Ajax.get({
			url: "api/endowments/",
			token: true,
			queryString: { list: true, event_id: event.id, limit: -1 },
		});
		if (res.type === "success") {
			const find = res.list.find((endo) => endo.destock <= 0);
			if (!find) {
				res = await Ajax.patch({
					url: `api/event-close/`,
					token: true,
					body: { id: event.id },
				});
				if (res.type === "success") {
					Notify.success("Evénement clôturé avec succès !");
					return this.setState({ loadingClose: false, event: res.item });
				} else {
					Notify.error(res.message);
				}
			} else {
				Notify.error("Certaines dotations n'ont pas été déstockées");
			}
		} else {
			Notify.error(res.message);
		}
		this.setState({ loadingClose: false });
	}

	renderNotFound() {
		const { classes } = this.props;
		return (
			<div className={classes.wrapper}>
				<Paper>
					<Box
						p={2}
						sx={{
							display: "flex",
							flexDirection: "column",
							alignContent: "center",
							alignItems: "center",
						}}
					>
						<WarningIcon sx={{ color: "error.main" }} />
						<Typography variant="body1" gutterBottom>
							L'évènement que vous recherchez n'a pas été trouvé...
						</Typography>
						<Link to="/event">
							<Button variant="outlined">Retour à la liste</Button>
						</Link>
					</Box>
				</Paper>
			</div>
		);
	}

	renderLoading() {
		return "Chargement...";
	}

	renderConfirm() {
		const { openClose } = this.state;
		return (
			<Confirm
				open={openClose}
				title="Confirmation"
				message="Voulez-vous vraiment clôturer cet évènement ? Cette action est irréversible."
				onClickAway={() =>
					this.setState({ openClose: false, loadingClose: false })
				}
				onValidate={() => this.onClose()}
			/>
		);
	}

	renderTabs() {
		const { user } = this.props;
		const { event, tab } = this.state;

		return (
			<Box sx={{ width: "100%", height: "inherit", minHeight: 700 }}>
				<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
					<Tabs value={tab} onChange={this.handleChangeTab}>
						<Tab label="Dotations" />
						<Tab label="Participants" />
					</Tabs>
				</Box>
				<Box hidden={tab !== 0} p={2} height="inherit">
					{tab === 0 && (
						<Endowment
							event_id={event.id}
							event_closed={event.closed}
							event_name={event.name}
							user={user}
							shop={{ id: event.shop_id }}
							refreshEvent={this.refreshEvent}
						/>
					)}
				</Box>
				<Box hidden={tab !== 1} p={2} height="inherit">
					{tab === 1 && (
						<Participant
							event_id={event.id}
							event_name={event.name}
							event_price={event.price}
							user={user}
							shop={{ id: event.shop_id }}
							refreshEvent={this.refreshEvent}
						/>
					)}
				</Box>
			</Box>
		);
	}

	renderContent() {
		const { classes, user } = this.props;
		const { event, loadingClose } = this.state;
		const date = event.event_date
			? moment(event.event_date).format("LL")
			: "A définir.";
		const shop =
			user.shops.find((shop) => parseInt(shop.id, 10) === event.shop_id)
				?.name || "";
		return (
			<div className={classes.wrapper}>
				<>
					<Typography variant="h2" component="h2" gutterBottom>
						Évènement: {event.name}{" "}
						{!!event.closed ? <LockIcon /> : <LockOpenIcon />}
					</Typography>
					<Grid spacing={3}>
						<Box key="1" sx={{ maxWidth: 400 }}>
							<Grid xs={6} spacing={0.5}>
								<Typography key="1">Boutique :</Typography>
								<Typography key="2" fontWeight={600}>
									{shop}
								</Typography>

								<Typography key="3">Date :</Typography>
								<Typography key="4" fontWeight={600}>
									{date} {event.event_hour || ""}
								</Typography>

								<Typography key="5">Format :</Typography>
								<Typography key="6" fontWeight={600}>
									{event.format_name || "Aucun"}
								</Typography>

								<Typography key="7">Immatriculation :</Typography>
								<Typography key="8" fontWeight={600}>
									{event.registration_name || "Aucune"}
								</Typography>

								<Typography key="9">Gamme :</Typography>
								<Typography key="10" fontWeight={600}>
									{event.taxonomy_value_name || "Aucune"}
								</Typography>
							</Grid>
						</Box>
						<Box key="2" sx={{ maxWidth: 400 }}>
							<Grid xs={6} spacing={0.5}>
								<Typography key="1">Prix :</Typography>
								<Tooltip key="2" title="Prix d'une place">
									<Typography fontWeight={600}>{event.price}€ </Typography>
								</Tooltip>

								<Typography key="3">Places :</Typography>
								<Tooltip key="4" title="Places restantes / places total">
									<Typography fontWeight={600}>
										{event.quantity - event.reserved_quantity} /{" "}
										{event.quantity}
									</Typography>
								</Tooltip>

								<Typography key="5">Coût :</Typography>
								<Tooltip key="6" title="Coût réel / coût prévu">
									<Typography fontWeight={600}>
										{event.real_cost.toFixed(2)}€ /{" "}
										{event.expected_cost.toFixed(2)}€
									</Typography>
								</Tooltip>

								<Typography key="7">CA :</Typography>
								<Tooltip key="8" title="CA réel / CA prévu">
									<Typography fontWeight={600}>
										{event.real_ca.toFixed(2)}€ / {event.expected_ca.toFixed(2)}
										€
									</Typography>
								</Tooltip>

								<Typography key="9">Marge :</Typography>
								<Tooltip key="10" title="Marge réelle / marge prévue">
									<Typography fontWeight={600}>
										{event.real_marge.toFixed(2)}€ /{" "}
										{event.expected_marge.toFixed(2)}€
									</Typography>
								</Tooltip>
							</Grid>
						</Box>
						<Button
							key="3"
							onClick={
								!event.closed && !loadingClose
									? () => this.setState({ openClose: true, loadingClose: true })
									: null
							}
							variant="contained"
							color="error"
							startIcon={
								loadingClose ? (
									<CircularProgress size={20} />
								) : (
									<WorkspacePremiumIcon />
								)
							}
							disabled={!!event.closed || loadingClose}
						>
							Clôturer l'évènement
						</Button>
					</Grid>
				</>
				{this.renderTabs()}
				{this.renderConfirm()}
			</div>
		);
	}

	render() {
		const { event, notFound } = this.state;
		if (notFound) {
			return this.renderNotFound();
		}
		if (!event) {
			return this.renderLoading();
		}
		return this.renderContent();
	}
}

EventView.propTypes = {
	classes: PropTypes.object.isRequired,
	redirect: PropTypes.func.isRequired,
	match: PropTypes.object,
	user: PropTypes.object,
};

export default withStyles(styles)(EventView);

const style =  theme => ({
  wrapper: {
    width: '100%',
    height: '100%',
  },
  listWrapper: {
    width: '100%',
    height: 'calc(100% - 120px)',
  },
  listContent: {
    width: '100%',
    height: '100%',
  },
  editPanelWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default style;
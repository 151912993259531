import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider } from '@mui/material';
import Ajax from '../../../utils/Ajax/index.js';
import { withStyles } from '@mui/styles';
import styles from './styles.js';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Gallery from '../../Gallery/index.js';
import Notify from '../../../components/base/Notify/index.js';

class GalleryModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      medias: [],
    };
    this.renderImageList = this.renderImageList.bind(this);
    this.handleValidate = this.handleValidate.bind(this);
  }

  async componentDidMount() {
    const listMedia = await this.getFiles('');
    this.setState({ medias: listMedia });
  }

  handleValidate() {
    return;
  }

  async getFiles() {
    const res = await Ajax.get(
      {
        url: 'api/gallery',
        queryString: {
          list: true,
        },
        token: true,
      }
    );
    if (res.type === "success") {
      return res.list;
    } else {
      Notify.error(res.message);
    }
    return [];
  }

  renderImageList() {
    const { medias } = this.state;
    if (medias && medias.length > 0) {
      return (
        <Box sx={{
          display: "flex",
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: 2,
          marginTop: 2,
          overflow: 'auto',
        }}>
          {medias.map((file) => (
            this.renderImagePreview(file)
          ))}
        </Box>
      )
    }
    return;
  }

  renderImagePreview(file) {
    const { classes, getUrl } = this.props;
    return (
      <Box key={file.id} onClick={() => getUrl(file.url)}>
        <LazyLoadImage
          src={file.url}
          alt={file.name}
          effect="blur"
          className={classes.imageItem}
        />
      </Box>
    )
  }

  render() {
    const { open, handleOpen, getUrl, canDelete } = this.props;
    return (
      <Dialog maxWidth="lg" onClose={handleOpen} open={open}>
        <DialogTitle>
          Médias
          <IconButton
            aria-label="close"
            onClick={handleOpen}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Gallery getUrl={getUrl} canDelete={canDelete} />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button variant="outlined" color="error" onClick={handleOpen}>Annuler</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

GalleryModal.propTypes = {
  classes: PropTypes.object.isRequired,
  handleOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  getUrl: PropTypes.func,
  canDelete: PropTypes.bool,
}

export default withStyles(styles)(GalleryModal);


import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import isNil from 'lodash/isNil';
import Stack from '@mui/material/Stack';

import AppBar from './AppBar';
import Config from '../../config';
import Ajax from '../../utils/Ajax';
import Notify from '../../components/base/Notify';

import styles from './styles.js';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.refreshToken = this.refreshToken.bind(this);
    this.handleLogOut = this.handleLogOut.bind(this);
  }

  /** Au mount du container : actions de type BDD */
  componentDidMount() {
    const { REFRESH_INTERVAL } = Config;
    this.refreshToken();
    const loading = document.getElementById('loading');
    if (!isNil(loading)) {
      loading.remove()
    }
    // demande un nouveau token régulièrement
    setInterval(this.refreshToken, REFRESH_INTERVAL);
  }

  async refreshToken() {
    const { onUser } = this.props;
    const { TOKEN_KEY } = Config;
    if (window.sessionStorage.getItem(TOKEN_KEY)) {
      const res = await Ajax.get({ url: `api/token/refresh`, token: true });
      if (res.type === 'success') {
        window.sessionStorage.setItem(TOKEN_KEY, res.token);
        return onUser(res.user);
      } else {
        window.sessionStorage.removeItem(TOKEN_KEY);
      }
    }
    onUser(Config.DEFAULT_USER);
  }

  handleLogOut() {
    this.props.onLogout();
  }

  setTitle() {
    const { APP_NAME } = Config;
    if (this.props.page && this.props.page.label) {
      document.title = `${APP_NAME} - ${this.props.page.label}`;
    } else {
      document.title = APP_NAME;
    }
  }

  render() {
    const { classes, user, page, ...others } = this.props;
    if (!user || !page) {
      return null;
    }
    this.setTitle();
    const { Node, appMenu, action } = page;
    return (
      <div className={classes.mainWrapper}>
        {appMenu && <AppBar user={user} onLogout={this.handleLogOut} redirect={this.props.redirect}/>}
        <div className={action ? classes.pageWrapper : classes.publicPageWrapper}>
          {Node && <Node title={page.label || ''} user={user} page={page} refreshToken={this.refreshToken} {...others} />}
        </div>
        <Stack spacing={1} className={classes.notifyContainer}>
         <Notify />
        </Stack>
      </div>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  page: PropTypes.object,
  redirect: PropTypes.func,
  user: PropTypes.object,
  onUser: PropTypes.func,
  onLogout: PropTypes.func,
}

export default withStyles(styles)(App);
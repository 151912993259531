import React from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';

/**
 * DataGridCell
 */
const DEFAULT_STYLE = {
  lineHeight: '51px',
  display: 'flex',
  alignItems: 'center',
};

const JUSTIFYCONTENT_ALIGN = {
  left: 'flex-start',
  right: 'flex-end',
  center: 'center'
};
class DataGridCell extends React.PureComponent {
  static defaultProps = {
    style: {},
  }

  render() {
    const {
      className,
      width,
      height,
      field,
      align,
      cellMode,
      rowId,
      colIndex,
      tabIndex,
      formattedValue,
      children,
      style,
    } = this.props;
    const useTooltip = !children && !isNil(formattedValue) && (typeof formattedValue === 'string' || typeof formattedValue === 'number');
    const justifyContent = JUSTIFYCONTENT_ALIGN[align || 'left'] || JUSTIFYCONTENT_ALIGN.left;
    return (
      <div
        className={`MuiDataGrid-cell MuiDataGrid-cell--textLeft ${className || ''}`}
        style={{ ...DEFAULT_STYLE, width, height, justifyContent }}
        field={field}
        cellmode={cellMode}
        row-id={rowId}
        col-index={colIndex}
        tab-index={tabIndex}
      >
        <div style={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          width: '100%',
        }}>
          <span className="MuiDataGrid-cell-content" style={{ maxWidth: '100%', maxHeight: '100%', position: 'relative', ...style }} title={useTooltip ? formattedValue : ''}>
            {children || formattedValue}
          </span>
        </div>
      </div>
    );
  }
}

DataGridCell.propTypes = {
  // Default cell props
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  field: PropTypes.string,
  cellMode: PropTypes.string,
  rowId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colIndex: PropTypes.number,
  tabIndex: PropTypes.number,
  value: PropTypes.any,
  children: PropTypes.any,
  // Custom props
  style: PropTypes.object,
}

export default DataGridCell;
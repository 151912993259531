import { createTheme  } from '@mui/material/styles';
import { frFR } from '@mui/material/locale';


const primary = '#0747A6';

const Theme = {
  palette: {
    primary: {
      main: primary,
      light: '#0656cc',
      dark: '#043884',
      contrastText: '#FDFDFD',
    },
    secondary: {
      main: '#F2B41B',
      light: '#F6CD65',
      dark: '#E7AA0D',
      contrastText: '#FDFDFD',
    },
    error: {
      main: '#D64045',
      contrastText: "#FFF",
    },
    warning: {
      main: '#F2B41B',
      contrastText: "#FFF",
    },
    success: {
      main: '#5FAB80',
      contrastText: "#FFF",
    },
    info: {
      main: primary,
      contrastText: "#FFF",
    },
    white: {
      main: '#FFF',
      contrastText: '#000',
    },
    black: {
      main: '#000',
      text: '#202020',
      light: '#666666',
      contrastText: '#FFF',
    },
    grey: {
      main: '#D6D6D6',
      light: '#F2F2F2',
      dark: '#B8B8B8',
      contrastText: '#000',
    },
    green: {
      main: '#417B5A',
      light: '#5FAB80',
      dark: '#315E44',
      contrastText: '#000',
    },
    red: {
      main: '#D64045',
      light: '#E2787C',
      dark: '#B9272C',
      contrastText: '#FDFDFD',
    },
  },
  typography: {
    fontFamily: '"Roboto", Arial, sans-serif',
    htmlFontSize: 16,
    h1: {
      fontFamily: '"Roboto", Arial, sans-serif',
      fontSize: '3rem',
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: 0.84,
      textTransform: 'uppercase',
    },
    h2: {
      fontFamily: '"Roboto", Arial, sans-serif',
      fontSize: '2.5rem',
      fontWeight: 600,
      letterSpacing: 0,
      lineHeight: 1.07,
    },
    h3: {
      fontFamily: '"Roboto", Arial, sans-serif',
      fontSize: '2rem',
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: 1.23,
    },
    h4: {
      fontFamily: '"Roboto", Arial, sans-serif',
      fontSize: '1.6rem',
      fontWeight: 500,
      letterSpacing: '0.02375rem',
      lineHeight: 1.4,
    },
    h5: {
      fontFamily: '"Roboto", Arial, sans-serif',
      fontSize: '1.4rem',
      fontWeight: 500,
      letterSpacing: '0.02375rem',
      lineHeight: 1,
    },
    h6: {
      fontFamily: '"Roboto", Arial, sans-serif',
      fontSize: '1.2rem',
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: 1.25,
    },
    body1: {
      fontFamily: '"Roboto", Arial, sans-serif',
      fontSize: '1rem',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 1.4,
    },
    body2: {
      fontFamily: '"Roboto", Arial, sans-serif',
      fontSize: '1.1rem',
      fontWeight: 100,
      letterSpacing: 0,
      lineHeight: 1.4,
    },
    subtitle1: {
      fontFamily: '"Roboto", Arial, sans-serif',
      fontSize: '1.15rem',
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: 1.4,
    },
    subtitle2: {
      fontFamily: '"Roboto", Arial, sans-serif',
      fontSize: '1rem',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 1.35,
    },
    button: {
      fontFamily: '"Roboto", Arial, sans-serif',
      fontSize: '0.875rem',
      fontWeight: 500,
      letterSpacing: '0.05rem',
      lineHeight: 2,
      textAlign: 'left',
      textTransform: 'none',
    },
    overline: {
      fontFamily: '"Roboto", Arial, sans-serif',
      fontSize: '0.69rem',
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: 1.27,
    },
    caption: {
      fontFamily: '"Roboto", Arial, sans-serif',
      fontSize: '0.9rem',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 1.2857,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          height: '100%',
        },
        body: {
          height: '100%',
          '& #root': {
            height: '100%',
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
        virtualScroller: {
          "::-webkit-scrollbar": {
            width: 8,
            height: 10,
          },
          "::-webkit-scrollbar-thumb": {
            background: primary,
            borderRadius: 5,
            height: '10px',
            border: '2px solid transparent',
          },
          scrollbarWidth: 'thin',
          scrollbarColor: `${primary} transparent`,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
        selectLabel: {
          fontSize: '1rem',
        },
        displayedRows: {
          fontSize: '1rem',
        },
      },
    },
    MuiChip: {      
      styleOverrides: {
        root: {
          paddingLeft: 12,
        },
      },
    },
    MuiListItemIcon:{
      styleOverrides: {
        root: {
          color: 'inherit',
        },
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'display' },
          style: {
            display: 'inline-block',
            textTransform: 'capitalize',
            padding: '8px 16px',
            margin: '8px !important',
            backgroundColor: primary,
            color: '#FFFFFF',
            minWidth: 100,
            textAlign: 'center',
            pointerEvents: 'none',
          },
        },
        {
          props: { variant: 'vcard-name' },
          style: {
            fontSize: 20,
            fontFamily: 'Poppins',
          },
        },
        {
          props: { variant: 'vcard-role' },
          style: {
            fontSize: 14,
            fontFamily: 'Poppins',
            fontWeight: 300,
          },
        },
        {
          props: { variant: 'vcard-tab' },
          style: {
            fontSize: 14,
            fontFamily: 'Poppins',
            fontWeight: 400,
          },
        },
        {
          props: { variant: 'vcard-list-primary' },
          style: {
            fontSize: 14,
            fontFamily: 'Poppins',
            fontWeight: 400,
            display: 'block',
          },
        },
        {
          props: { variant: 'vcard-list-secondary' },
          style: {
            fontSize: 14,
            fontFamily: 'Poppins',
            fontWeight: 400,
            color: '#888888',
            display: 'block',
          },
        },
      ],
    },
    MuiAlert: {
      styleOverrides: {
        filledSuccess: {
          color: '#FFF',
        },
      },
    },
    MuiFab: {
      variant: [
        {
          props: { color: 'black' },
          style: {
            backgroundColor: '#000',
          },
        },
      ]
    },
  },
};

export default createTheme(Theme, frFR);

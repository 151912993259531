import React from 'react';
import PropTypes from 'prop-types';
import forEach from 'lodash/forEach';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { withStyles } from '@mui/styles';

import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

import ConfigPage from '../Router/path';

import Confirm from '../../components/base/Confirm';

import styles from './styles.js';

class AppBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      openConfirmLogout: false,
    };

    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onLogoutBtn = this.onLogoutBtn.bind(this);
    this.onCancelLogout = this.onCancelLogout.bind(this);
    this.onValidateLogout = this.onValidateLogout.bind(this);
  }

  onMouseEnter() {
    this.setState({ open : true });
  }

  onMouseLeave() {
    this.setState({ open: false });
  }

  onLogoutBtn() {
    this.setState({ openConfirmLogout: true });
  }

  onCancelLogout() {
    this.setState({ openConfirmLogout: false });
  }

  onValidateLogout() {
    const { onLogout } = this.props;
    this.setState({ openConfirmLogout: false }, onLogout );
  }

  onMenuItem(conf) {
    const { redirect } = this.props;
    redirect(conf.route);
  }

  renderConfirmLogout() {
    const { openConfirmLogout } = this.state;
    if (!openConfirmLogout) {
      return null;
    }
    return (
      <Confirm
        open={openConfirmLogout}
        onClickAway={this.onCancelLogout}
        onValidate={this.onValidateLogout}
        title="Déconnexion"
        message="Êtes vous sûr de vouloir vous déconnecter ?"
        cancel="Annuler"
        validate="Se déconnecter"
      />
    );
  }

  renderMenuItem(conf) {
    const { classes } = this.props;
    return (
      <MenuItem key={conf.label} className={classes.menuItem} onClick={() => this.onMenuItem(conf)}>
        <ListItemIcon>
          {conf.icon}
        </ListItemIcon>
        <span className={`${classes.listItemText} listItemText`}>
          {conf.label}
        </span>
      </MenuItem>
    );
  }

  renderMenu() {
    const { user } = this.props;
    const items = [];
    forEach(ConfigPage, conf => {
      if (conf.showInMenu && user?.actions && user?.actions[conf.action]) {
        items.push(this.renderMenuItem(conf));
      }
    });
    return items;
  }

  renderOptions() {
    const { classes } = this.props;
    return (
      <MenuItem key="logout" className={classes.menuItem} onClick={this.onLogoutBtn}>
        <ListItemIcon>
          <LogoutOutlinedIcon />
        </ListItemIcon>
        <span className={`${classes.listItemText} listItemText`}>
          Déconnexion
        </span>
      </MenuItem>
    );
  }

  render() {
    const { classes } = this.props;
    const { open } = this.state;
    const openClass = open ? classes.asideOpen : classes.asideClose;
    return (
      <>
        <aside className={`${classes.asideContainer} ${openClass}`} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
          <MenuList>
            {this.renderMenu()}
          </MenuList>
          <MenuList>
            {this.renderOptions()}
          </MenuList>
        </aside>
        {this.renderConfirmLogout()}
      </>
    );
  }
}

AppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  redirect: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  user: PropTypes.object,
}

export default withStyles(styles)(AppBar);
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/fr';
import CssBaseline from '@mui/material/CssBaseline';
import isNil from 'lodash/isNil';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import Device from '../../utils/Device';
import Prompt from '../../components/base/Prompt';

import Theme from '../../theme';
import Router from '../Router';

import Config from '../../config';

class Main extends React.Component {
  constructor(props) {
    super(props);
    const shopJson = window.sessionStorage.getItem(Config.SHOP_KEY) || null;
    let shop = null;
    if (shopJson) {
      try {
        shop = JSON.parse(shopJson);
      } catch (e) { }
    }
    this.state = {
      user: null,
      shop,
    };
    this.handleUser = this.handleUser.bind(this);
    this.handleShop = this.handleShop.bind(this);
    this.handleLogOut = this.handleLogOut.bind(this);

  }
  
  /** SETUP */
  componentDidMount() {
    const html = document.querySelector('html');
    if (isNil(Device.mobile())) {
      html.className += 'desktop';
    } else {
      html.className += 'mobile';
    }
  }
  /** HANDLERS */
  handleUser(user) {
    this.setState({ user });
  }

  handleShop(shop) {
    this.setState({ shop });
  }

  handleLogOut() {
    const { TOKEN_KEY, SHOP_KEY } = Config;
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.removeItem(SHOP_KEY);
    this.handleUser(Config.DEFAULT_USER);
    this.handleShop(null);
  }

  render() {
    return (
      <ThemeProvider theme={Theme} >
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
          <BrowserRouter getUserConfirmation={Prompt.getUserConfirmation}>
            <Router
              {...this.state}
              onUser={this.handleUser}
              onShop={this.handleShop}
              onLogout={this.handleLogOut}
            />
          </BrowserRouter>
        </LocalizationProvider>
      </ThemeProvider>
    );
  }
}

Main.propTypes = {

}

export default Main;
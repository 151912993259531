import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Box, Divider, Stack, Typography } from '@mui/material';
import Notify from '../../components/base/Notify';
import Ajax from '../../utils/Ajax';
import styles from './styles.js';
import CardItem from './CardItem';
import EventList from './EventList';
import Profit from './Profit';
import { withStyles } from '@mui/styles';

class Dashboard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      intlNumber: new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }),
      caAverage: 0,
      marginAverage: 0,
      upcomingEventCount: 0,
      todayEvents: [],
      eventsStats: [],
    };
  }

  componentDidMount() {
    this.getCA();
    this.getMargin();
    this.getUpcomingEvents();
    this.getTodayEvents();
    this.getStatsEvents();
  }

  async getCA() {
    const { shop } = this.props;

    const res = await Ajax.get(
      {
        url: 'api/dash-ca',
        queryString: {
          shop_id: shop.id,
        },
        token: true,
      }
    );
    if (res.type === "success") {
      this.setState({ caAverage: res.item.caAverage });
    } else {
      Notify.error(`CA non disponible.`);
    }
    return [];
  }

  async getMargin() {
    const { shop } = this.props;

    const res = await Ajax.get(
      {
        url: 'api/dash-margin',
        queryString: {
          shop_id: shop.id,
        },
        token: true,
      }
    );
    if (res.type === "success") {
      this.setState({ marginAverage: res.item.marginAverage });

    } else {
      Notify.error(`Marge non disponible.`);
    }
    return [];
  }

  async getUpcomingEvents() {
    const { shop } = this.props;

    const res = await Ajax.get(
      {
        url: 'api/dash-upcoming-events',
        queryString: {
          shop_id: shop.id,
        },
        token: true,
      }
    );
    if (res.type === "success") {
      this.setState({ upcomingEventCount: res.item.count });
    } else {
      Notify.error(`Aucun évènements à venir.`);
    }
    return [];
  }

  async getTodayEvents() {
    const { shop } = this.props;

    const res = await Ajax.get(
      {
        url: 'api/dash-today-events',
        queryString: {
          shop_id: shop.id,
        },
        token: true,
      }
    );
    if (res.type === "success") {
      this.setState({ todayEvents: res.list });
    } else {
      Notify.error(`Aucun évènements aujourd'hui.`);
    }
    return [];
  }

  async getStatsEvents() {
    const { shop } = this.props;
    const res = await Ajax.get(
      {
        url: 'api/dash-events-stats',
        queryString: {
          shop_id: shop.id,
        },
        token: true,
      }
    );
    if (res.type === "success") {
      this.setState({ eventsStats: res.list });
    } else {
      Notify.error(`Aucune données disponible.`);
    }
    return [];
  }

  render() {
    const { caAverage, marginAverage, upcomingEventCount, todayEvents, eventsStats, intlNumber } = this.state;
    const { classes, user, shop } = this.props;
    return (
      <div className={classes.wrapper} >
        <Typography variant="h4" gutterBottom>Tableau de bord</Typography>
        <Divider/>
        <Typography variant="h5" gutterBottom mt={2}>Boutique: {user.shops.find(shopList => parseInt(shopList.id, 10) === shop.id)?.name || 'Non définit'}</Typography>
        <Grid container spacing={2} mt={2}>
          <Grid xs={12} md={6}>
            <Box>
              <Typography variant="h5" gutterBottom>Chiffre clés: 30 derniers tournois clôturés</Typography>
            </Box>
            <Stack mt={3} direction="row" spacing={2}>
              <CardItem title="CA moyen" valueDisplay={intlNumber.format(caAverage)} />
              <CardItem title="Marge moyenne" valueDisplay={intlNumber.format(marginAverage)} />
              <CardItem title="Tournois à venir" valueDisplay={upcomingEventCount} />
            </Stack>
          </Grid>
          <Grid xs={12} md={6}>
            <Box>
              <Typography variant="h5" gutterBottom>Tournois du jour:</Typography>
              <EventList events={todayEvents} user={user} />
            </Box>
          </Grid>
          <Grid xs={12} mt={2}>
            <Box>
              <Typography variant="h5" gutterBottom>Rentabilité: 30 derniers tournois clôturés</Typography>
              <Profit events={eventsStats} />
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  redirect: PropTypes.func.isRequired,
  match: PropTypes.object,
  user: PropTypes.object,
  shop: PropTypes.object,
}

export default withStyles(styles)(Dashboard);

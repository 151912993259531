import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Typography } from '@mui/material';

class CardItem extends React.Component {

  render() {
    const { title, valueDisplay } = this.props;
    return (
      <Card sx={{ minWidth: 100 }}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {title}
          </Typography>
          <Typography variant="h5" component="h5" textAlign="center" sx={{color: 'warning.main'}}>
            {valueDisplay}
          </Typography>
        </CardContent>
      </Card>
    );
  }
}

CardItem.propTypes = {
  match: PropTypes.object,
  user: PropTypes.object,
  valueDisplay: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string,
}

export default CardItem;

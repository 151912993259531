import React from 'react';
import { withStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import EditModalWrapper from '../../../containers/EditableList/EditModalWrapper';
// import { EDITMODAL_MODES } from '../../../containers/EditableList/constants';
import Notify from '../../../components/base/Notify';
import Ajax from '../../../utils/Ajax';
import _debounce from 'lodash/debounce';
import styles from './styles';
import { isEmpty } from 'lodash';

class EditModal extends EditModalWrapper {

  constructor(props) {
    super(props);
    this.getEventsOptionsLabel = this.getEventsOptionsLabel.bind(this);
    this.getUsersOptionsLabel = this.getUsersOptionsLabel.bind(this);

    if (props.item && !props.item.event_id && props.options && props.options.event_id && props.options.event_name) {
      this.state.form.event_id = props.options.event_id;
      this.state.form.event_name = props.options.event_name;
      this.state.form.price = props.options.event_price;
    }
  }

  extendState() {
    return { events: [], users: [], loadingEvent: false, loadingUser: false };
  }

  async componentDidMount() {
    const listEvents = await this.getEvents('');
    const listUsers = await this.getUsers('');
    this.setState({ events: listEvents, users: listUsers });
  }

  async getEvents(value) {
    this.setState({ loadingEvent: true });
    const res = await Ajax.get(
      {
        url: 'api/events',
        queryString: {
          list: true,
          limit: 50,
          search: value,
        },
        token: true,
      }
    );
    this.setState({ loadingEvent: false });
    if (res.type === "success") {
      return res.list;
    } else {
      Notify.error(`${res.message}`);
    }
    return [];
  }

  async getUsers(value) {
    this.setState({ loadingUser: true });
    const res = await Ajax.get(
      {
        url: 'api/clients-autocomplete',
        queryString: { limit: 50, search: value, order: 'name' },
        token: true,
      }
    );
    this.setState({ loadingUser: false });
    if (res.type === "success") {
      return res.list;
    } else {
      Notify.error(`${res.message}`);
    }
    return [];
  }

  getUsersOptions() {
    const { users, form } = this.state;
    const result = users.map(e => e.id);
    if (form && form.parkage_user_id && !result.find(id => id === form.parkage_user_id)) {
      result.unshift(form.parkage_user_id);
    }
    return result;
  }

  getUsersOptionsLabel(userId) {
    const { users, form } = this.state;
    if (form && form.parkage_user_id && form.parkage_user_id === userId) {
      return `${form.username}`;
    }
    const findOption = users.find(e => e.id === userId);
    if (findOption) {
      return `${findOption.name}`;
    }
    return '';
  }

  getEventsOptions() {
    const { events, form } = this.state;
    const result = events.map(e => e.id);
    if (form && form.event_id && !result.find(id => id === form.event_id)) {
      result.unshift(form.event_id);
    }
    return result;
  }

  getEventsOptionsLabel(eventId) {
    const { events, form } = this.state;
    if (form && form.event_id && form.event_id === eventId) {
      return `${form.event_name}`;
    }
    const findOption = events.find(e => e.id === eventId);
    if (findOption) {
      return `${findOption.name}`;
    }
    return '';
  }

  handleChangeSelect(name, value) {
    if (name) {
      this.setState(prev => {
        const form = { ...prev.form };
        if (name === 'event_id') {
          const { events } = this.state;
          const event = value ? events.find(p => p.id === value) : null;
          form.event_name = event ? event.name : '';
          form.shop_id = event ? event.shop_id : '';
        } 
        if (name === 'parkage_user_id') {
          const { users } = this.state;
          const user = value ? users.find(p => p.id === value) : null;
          form.username = user ? user.name : '';
          form.email = user ? user.email : '';
        }
        form[name] = value;
        return { form, missingFields: [], invalidFields: [] };
      });
    }
  }

  renderContent() {
    const { item, options } = this.props;
    const { loadingEvent, loadingUser } = this.state;
    const { event_id } = options;
    return (
      <Stack
        direction="row"
        justifyContent="space-around"
        divider={<Divider orientation="vertical" flexItem />}
      >
        <Box sx={{ width: '40%' }}>
          <Stack direction="column" spacing={2}>
            {!isEmpty(item) && this.renderTextField({ key: 'id', label: 'ID', disabled: true  })}
            {/* {this.renderTextField({ key: 'parkage_user_id', label: 'ParkageID', placeholder: 'Email ou pseudo' })} */}
            {this.renderSelect({
              key: 'parkage_user_id',
              label: 'Client Parkage',
              placeholder: 'Email ou pseudo',
              options: this.getUsersOptions(),
              multiple: false,
              loading: loadingUser,
              helperText: 'Choisir dans la liste ou tapez le terme de recherche',
              getOptionLabel: this.getUsersOptionsLabel,
              onInputChange: _debounce(async evt => {
                const { value } = evt.target;
                const list = await this.getUsers(value);
                this.setState({ users: list });
              }, 300),
              filterOptions: options => options
            })}
            {this.renderTextField({ key: 'lastname', label: 'Nom' })}
            {this.renderTextField({ key: 'firstname', label: 'Prénom' })}
            {this.renderTextField({ key: 'email', label: 'Email' })}
            {this.renderTextField({ key: 'price', label: 'Montant à régler' })}
            {this.renderSelect({
              key: 'payment_type',
              label: 'Type du payement',
              placeholder: 'Type du payement',
              options: ['cb', 'cash', 'other'],
              multiple: false,
              getOptionLabel: opt => options.typePayment[opt] || '',
            })}
            {this.renderSelect({
              key: 'state',
              label: 'Statut du payement',
              placeholder: 'Statut',
              options: ['wait', 'cancel', 'paid'],
              multiple: false,
              getOptionLabel: opt => options.statePayment[opt] || '',
            })}
          </Stack>
        </Box>
        <Box sx={{ width: '40%' }}>
          <Stack direction="column" spacing={2}>
            {this.renderTextField({ key: 'username', label: 'Pseudonyme' })}
            {this.renderTextField({ key: 'identifier', label: 'Matricule' })}
            {this.renderSelect({
              key: 'event_id',
              label: 'Évènement',
              placeholder: 'Évènement',
              options: this.getEventsOptions(),
              multiple: false,
              loading: loadingEvent,
              disabled: event_id ? true : false,
              value: event_id ? event_id : null,
              helperText: 'Choisir dans la liste ou tapez le terme de recherche',
              getOptionLabel: this.getEventsOptionsLabel,
              onInputChange: _debounce(async evt => {
                const { value } = evt.target;
                const list = await this.getEvents(value);
                this.setState({ events: list });
              }, 300),
              filterOptions: options => options
            })}
            {this.renderTextField({ key: 'comment', label: 'Commentaire', multiline: true })}
          </Stack>
        </Box>
      </Stack>
    );
  }
}

export default withStyles(styles)(EditModal);

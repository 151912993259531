import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';

import LogoUrl from '../../res/parkage-logo.png';

import Notify from '../../components/base/Notify';
import Ajax from '../../utils/Ajax';
import Config from '../../config';

import styles from './styles.js';
import { CircularProgress, FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const errorMessages = {
  notFound: 'Ce login n\'existe pas',
  passMismatch: 'Mot de passe incorrect',
};

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      login: '',
      password: '',
      loadingLogin: false,
      shopList: [],
      loadingShops: false,
      renderMissing: false,
      error: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.getShopList = this.getShopList.bind(this);
  }

  /**
 * GET
 */
  async getShopList() {
    this.setState({ loadingShops: true });
    const res = await Ajax.get({ url: `api/shops?list=true&limit=100`, token: false });
    if (res.type === 'success' && res.list.length > 0) {
      this.setState({ shopList: res.list });
    } else {
      Notify.error(`Aucune boutiques trouvées.`);
    }
    this.setState({ loadingShops: false });
  }

  /**
   * HANDLERS
   */
  handleChange(evt) {
    this.setState({ [evt.target.name]: evt.target.value, renderMissing: false });
  }

  async handleLogin() {
    const { onUser, shop } = this.props;
    const { login, password } = this.state;
    if (!login || !password || !shop) {
      return this.setState({ renderMissing: true });
    }
    this.setState({ loadingLogin: true });
    const res = await Ajax.get(
      {
        url: 'api/token',
        queryString: {
          login,
          password,
          shop: shop.name
        },
        token: false,
      }
    );
    if (res.type === 'error') {
      Notify.error(errorMessages[res.error] || res.message);
      return this.setState({ loadingLogin: false, error: res.error });
    }
    window.sessionStorage.setItem(Config.TOKEN_KEY, res.token);
    window.sessionStorage.setItem(Config.SHOP_KEY, JSON.stringify(shop));
    onUser(res.user);
  }
  
  componentDidMount() {
    this.getShopList();
  }

  /**
   * RENDER
   */
  render() {
    const { classes, shop, onShop } = this.props;
    const { login, password, loadingShops, shopList, loadingLogin, renderMissing, error } = this.state;
    return (
      <Box
        className={classes.wrapper}
      >
        <Stack direction="row" spacing={6} sx={{ borderRadius: 2, color: 'white.main', backgroundColor: 'primary.main', padding: 8 }}>
          <Stack spacing={2}>
            <img className={classes.logo} src={LogoUrl} alt="Parkage" />
            <Typography variant="h3">Module de gestion</Typography>
            <Typography variant="h3">des évènements</Typography>
          </Stack>
          <Paper className={classes.content} elevation={2}>
            <form id="Login-form">
              <Stack spacing={2}>
                <TextField
                  required
                  name="login"
                  value={login}
                  label="Login"
                  autoComplete='login'
                  error={(renderMissing && !login) || error === 'notFound'}
                  disabled={loadingLogin}
                  onChange={this.handleChange}
                />
                <TextField
                  required
                  name="password"
                  value={password}
                  label="Mot de passe"
                  type="password"
                  autoComplete='password'
                  error={(renderMissing && !password) || error === 'passMismatch'}
                  disabled={loadingLogin}
                  onChange={this.handleChange}
                />               
                <FormControl fullWidth>
                  <InputLabel id="select-shop-label" error={renderMissing && !shop} required>
                    Boutique{' '}{loadingShops && <CircularProgress size={16} />}
                  </InputLabel>
                  <Select
                    labelId="select-shop-label"
                    label="Boutique"
                    value={shop || ''}
                    disabled={loadingShops || loadingLogin}
                    error={renderMissing && !shop}
                    onChange={evt => onShop(evt.target.value || null)}
                  >
                    {shopList.map(shop => (
                      <MenuItem key={shop.id} value={shop}>{shop.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button variant="contained" onClick={this.handleLogin} disabled={loadingLogin} fullWidth>Connexion</Button>
                {loadingLogin && <LinearProgress color="secondary" />}
              </Stack>
            </form>
          </Paper>
        </Stack>
      </Box>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  redirect: PropTypes.func.isRequired,
  user: PropTypes.object,
  onUser: PropTypes.func.isRequired,
  onShop: PropTypes.func.isRequired,
  shop: PropTypes.object,
}
export default withStyles(styles)(Login);

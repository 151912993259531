import React from 'react';
import { withStyles } from '@mui/styles';
import isEqual from 'lodash/isEqual';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import EditModalWrapper from '../../../containers/EditableList/EditModalWrapper';
// import { EDITMODAL_MODES } from '../../../containers/EditableList/constants';
import Notify from '../../../components/base/Notify';
import _debounce from 'lodash/debounce';

import styles from './styles';
import moment from 'moment';
import Ajax from '../../../utils/Ajax';
import { Button } from '@mui/material';
import GalleryModal from '../GalleryModal';
import { isEmpty } from 'lodash';

class EditModal extends EditModalWrapper {

  constructor(props) {
    super(props);
    this.getFormatsOptionsLabel = this.getFormatsOptionsLabel.bind(this);
    this.getRegistrationsOptionsLabel = this.getRegistrationsOptionsLabel.bind(this);
    this.getTaxoValueOptionsLabel = this.getTaxoValueOptionsLabel.bind(this);
    this.handleGalleryModal = this.handleGalleryModal.bind(this);
    this.getUrl = this.getUrl.bind(this);
  }
  extendState() {
    return { openGallery: false, hasFormatChanged: false, hasRegistrationChanged: false, loadingTaxonomy: false, loadingFormat: false, loadingRegistration: false, formats: [], registrations: [], taxonomy_value: [] };
  }

  async componentDidMount() {
    const listFormat = await this.getFormatSearch('');
    const listRegistration = await this.getRegistrationSearch('');
    const listTaxonomyValue = await this.getTaxonomyValueSearch('');

    this.setState({ formats: listFormat, registrations: listRegistration, taxonomy_value: listTaxonomyValue });
  }

  async componentDidUpdate(previousProps, previousState) {
    const { form } = this.state;
    if (previousState.form.taxonomy_value_id !== form.taxonomy_value_id) {
      const listFormat = await this.getFormatSearch('');
      const listRegistration = await this.getRegistrationSearch('');
      if (!isEqual(previousState.formats, listFormat)) {
        this.setState(prev => {
          return ({ form: { ...prev.form, format_id: null, format_name: null }, hasFormatChanged: true, formats: listFormat })
        });
        // this.setState({ formats: listFormat });
      }
      if (!isEqual(previousState.registrations, listRegistration)) {
        this.setState(prev => {
          return ({ form: { ...prev.form, registration_id: null, registration_name: null }, hasRegistrationChanged: true, registrations: listRegistration })
        });
        // this.setState({ registrations: listRegistration });
      }
    }
  }

  compileForm() {
    const output = { ...this.state.form };
    if (output.event_date && moment.isMoment(output.event_date)) {
      output.event_date = moment(output.event_date).format('YYYY-MM-DD');
    }
    if (output.event_hour && moment.isMoment(output.event_hour)) {
      output.event_hour = moment(output.event_hour).format('HH:mm');
    }
    return output;
  }

  async getTaxonomyValueSearch(value) {
    this.setState({ loadingTaxonomy: true, loadingFormat: true, loadingRegistration: true });
    const res = await Ajax.get(
      {
        url: 'api/gamme-autocomplete',
        queryString: {
          search: value,
        },
        token: true,
      }
    );
    this.setState({ loadingTaxonomy: false, loadingFormat: false, loadingRegistration: false });
    if (res.type === "success") {
      return res.list;
    } else {
      Notify.error(`Aucune gammes trouvées.`);
    }
    return [];
  }

  async getFormatSearch(value) {
    const { form } = this.state;
    this.setState({ loadingFormat: true });
    const res = await Ajax.get(
      {
        url: 'api/formats',
        queryString: {
          list: true,
          limit: 25,
          name: value,
          taxonomy_value_id: form.taxonomy_value_id || ''
        },
        token: true,
      }
    );
    this.setState({ loadingFormat: false });
    if (res.type === "success") {
      return res.list;
    } else {
      Notify.error(`Aucun Format trouvés.`);
    }
    return [];
  }


  async getRegistrationSearch(value) {
    const { form } = this.state;
    this.setState({ loadingRegistration: true });
    const res = await Ajax.get(
      {
        url: 'api/registrations',
        queryString: {
          list: true,
          limit: 25,
          name: value,
          taxonomy_value_id: form.taxonomy_value_id || ''
        },
        token: true,
      }
    );
    this.setState({ loadingRegistration: false });
    if (res.type === "success") {
      return res.list;
    } else {
      Notify.error(`Aucune immatriculation trouvées.`);
    }
    return [];
  }

  handleGalleryModal() {
    this.setState(prev => {
      return ({ openGallery: !prev.openGallery })
    });
  }

  /**
   * AUTOCOMPLETE SELECT OPTIONS
   */

  getTaxoValueOptions() {
    const { taxonomy_value, form } = this.state;
    const result = taxonomy_value.map(tv => tv.id);
    if (form && form.event_id && !result.find(id => id === form.taxonomy_value_id)) {
      result.unshift(form.taxonomy_value_id);
    }
    return result;
  }

  getTaxoValueOptionsLabel(tvId) {
    const { taxonomy_value, form } = this.state;
    if (form && form.taxonomy_value_id && form.taxonomy_value_id === tvId) {
      return `${form.taxonomy_value_name}`;
    }
    const findOption = taxonomy_value.find(e => e.id === tvId);
    if (findOption) {
      return `${findOption.name}`;
    }
    return '';
  }

  getFormatsOptions() {
    const { formats, hasFormatChanged, form } = this.state;
    const result = formats.map(f => f.id);
    if (form && form.format_id && !hasFormatChanged && !result.find(id => id === form.format_id)) {
      result.unshift(form.format_id);
    }
    return result;
  }

  getFormatsOptionsLabel(formatId) {
    const { formats, form } = this.state;
    if (form && form.format_id && form.format_id === formatId) {
      return `${form.format_name}`;
    }
    const findOption = formats.find(f => f.id === formatId);
    if (findOption) {
      return `${findOption.name}`;
    }
    return '';
  }

  getRegistrationsOptions() {
    const { registrations, hasRegistrationChanged, form } = this.state;
    const result = registrations.map(r => r.id);
    if (form && form.registration_id && !hasRegistrationChanged && !result.find(id => id === form.registration_id)) {
      result.unshift(form.registration_id);
    }
    return result;
  }

  getRegistrationsOptionsLabel(regId) {
    const { registrations, form } = this.state;
    if (form && form.registration_id && form.registration_id === regId) {
      return `${form.registration_name}`;
    }
    const findOption = registrations.find(r => r.id === regId);
    if (findOption) {
      return `${findOption.name}`;
    }
    return '';
  }

  getUrl(id, url) {
    this.handleGalleryModal();
    this.setState(prev => {
      return ({ form: { ...prev.form, image_id: id, image_url: url } })
    });
  }

  handleChangeSelect(name, value) {
    if (name) {
      this.setState(prev => {
        const form = { ...prev.form };
        if (name === 'taxonomy_value_id') {
          const { taxonomy_value } = this.state;
          const tv = value ? taxonomy_value.find(p => p.id === value) : null;
          form.taxonomy_value_name = tv ? tv.name : '';
        } else if (name === 'format_id') {
          const { formats } = this.state;
          const format = value ? formats.find(p => p.id === value) : null;
          form.format_name = format ? format.name : '';
        } else if (name === 'registration_id') {
          const { registrations } = this.state;
          const registration = value ? registrations.find(p => p.id === value) : null;
          form.registration_name = registration ? registration.name : '';
        }
        form[name] = value;
        return { form, missingFields: [], invalidFields: [] };
      });
    }
  }

  renderContent() {
    const { options, item, classes } = this.props;
    const { loadingTaxonomy, loadingFormat, loadingRegistration, openGallery } = this.state;
    const { shops } = options;
    return (
      <Stack
        direction="row"
        justifyContent="space-around"
        divider={<Divider orientation="vertical" flexItem />}
        className={classes.eventModal}
      >
        <Box sx={{ width: '40%' }}>
          <Stack direction="column" spacing={2}>
            {!isEmpty(item) && this.renderTextField({ key: 'id', label: 'ID', disabled: true })}
            {!isEmpty(item) && this.renderTextField({ key: 'created_date', label: 'Date de Création', disabled: true })}
            {this.renderImage({ key: 'image_url', label: 'Image' })}
            <Button variant="outlined" onClick={this.handleGalleryModal}>Choisir une image</Button>
            {this.renderTextField({ key: 'name', label: 'Nom' })}
            {this.renderSelect({
              key: 'shop_id',
              label: 'Boutique',
              placeholder: 'Boutique',
              options: shops.map(shop => parseInt(shop.id, 10)),
              multiple: false,
              disabled: !isEmpty(item) ? true : false,
              getOptionLabel: opt => shops.find(shop => parseInt(shop.id, 10) === opt)?.name || '',
            })}
            {this.renderDatePicker({ key: 'event_date', label: 'Date' })}
            {this.renderTimePicker({ key: 'event_hour', label: 'Heure' })}
            {this.renderSwitch({ key: 'visibility', label: 'Visible' })}
            {this.renderSwitch({ key: 'on_demand', label: 'À la demande' })}
          </Stack>
        </Box>
        <Box sx={{ width: '40%' }}>
          <Stack direction="column" spacing={2}>
            {this.renderSelect({
              key: 'taxonomy_value_id',
              label: 'Gamme',
              placeholder: 'Gamme',
              options: this.getTaxoValueOptions(),
              multiple: false,
              loading: loadingTaxonomy,
              helperText: 'Choisir dans la liste ou tapez le terme de recherche',
              getOptionLabel: this.getTaxoValueOptionsLabel,
              onInputChange: _debounce(async evt => {
                const { value } = evt.target;
                const list = await this.getTaxonomyValueSearch(value);
                this.setState({ taxonomy_value: list });
              }, 300),
              filterOptions: options => options
            })}
            {this.renderSelect({
              key: 'format_id',
              label: 'Format',
              placeholder: 'Format',
              options: this.getFormatsOptions(),
              multiple: false,
              disabled: loadingTaxonomy,
              loading: loadingFormat,
              helperText: 'Choisir dans la liste ou tapez le terme de recherche',
              getOptionLabel: this.getFormatsOptionsLabel,
              onInputChange: _debounce(async evt => {
                const { value } = evt.target;
                const list = await this.getFormatSearch(value);
                this.setState({ formats: list });
              }, 300),
              filterOptions: options => options
            })}
            {this.renderTextField({ key: 'format_description', label: 'Description Format' })}
            {this.renderSelect({
              key: 'registration_id',
              label: 'Immatriculation',
              placeholder: 'Immatriculation',
              options: this.getRegistrationsOptions(),
              multiple: false,
              disabled: loadingTaxonomy,
              loading: loadingRegistration,
              helperText: 'Choisir dans la liste ou tapez le terme de recherche',
              getOptionLabel: this.getRegistrationsOptionsLabel,
              onInputChange: _debounce(async evt => {
                const { value } = evt.target;
                const list = await this.getRegistrationSearch(value);
                this.setState({ registrations: list });
              }, 300),
              filterOptions: options => options
            })}
            {this.renderTextField({ key: 'quantity', label: 'Nombre de place', type: 'number' })}
            {this.renderTextField({ key: 'price', label: 'Prix', type: 'number' })}
            <Box sx={{ width: '100%' }}>
              {this.renderQuill({ key: 'description', label: 'Description' })}
            </Box>
          </Stack>
        </Box>
        <GalleryModal open={openGallery} handleOpen={this.handleGalleryModal} getUrl={this.getUrl} canDelete={false} />
      </Stack>
    );
  }
}

export default withStyles(styles)(EditModal);

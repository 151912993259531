import isNil from 'lodash/isNil';
import forEach from 'lodash/forEach';

import Config from '../../config';

class Ajax {
  async fetch(url, options) {
    if (isNil(url) || url === '') {
      return { type: "error", message: 'Missing Url', data: {} }
    }
    options = options || {};
    options.credentials = 'include';
    const methods = ['GET', 'POST', 'PUT', 'PATCH', 'DELETE'];
    options.method = (methods.indexOf(options.method) !== -1) ? options.method : 'GET';

    //Body
    if (options.method === 'POST' || options.method === 'PUT' || options.method === 'PATCH') {
      options.body = options.body || {};
      if (!(options.body instanceof FormData)) {
        let data = options.body;
        let used = false;
        let body = {};
        options.body = new FormData();
        forEach(data, (value, key) => {
          if (value instanceof File) {
            options.body.append(key, value);
          } else {
            used = true;
            body[key] = value;
          }
        });
        if (used) {
          try {
            body = JSON.stringify(body);
            options.body.append('json', body);
          } catch (e) {
            console.error(e);
          }
        }
      }
    } else if (!isNil(options.body)) {
      delete options.body;
    }
    if (!isNil(options.queryString)) {
      const queryStringTab = [];
      forEach(options.queryString, (val, key) => {
        if (Array.isArray(val)) {
          forEach(val, subValue => {
            queryStringTab.push(`${encodeURIComponent(key)}=${encodeURIComponent(subValue)}`);
          });
        } else {
          queryStringTab.push(`${encodeURIComponent(key)}=${encodeURIComponent(val)}`);
        }
      });
      url = `${url}?${queryStringTab.join('&')}`;
      delete options.queryString;
    }
    //Url
    if (url.substr(0, 3) === 'api') {
      const { TOKEN_KEY, HOST, PROTOCOL, PORT } = Config;
      let port = '';
      if (PORT !== '') {
        port = ':' + PORT;
      }
      url = `${PROTOCOL}//${HOST}${port}/${url}`;
      //Token
      const token = window.sessionStorage.getItem(TOKEN_KEY);
      if (!isNil(token) && token !== '' && options.token) {
        options.headers = new Headers({
          'Authorization': `Bearer ${token}`,
        });
        delete options.token;
      }

      if (options.onEvent) {
        const eventUrl = encodeURIComponent(url);
        const events = new EventSource(`${PROTOCOL}//${HOST}${port}/api?__event=${eventUrl}`);
        events.onerror = () => events.close();
        return new Promise(resolve => {
          let eventId = null;
          events.onmessage = async event => {
            let parsedData;
            try {
              parsedData = JSON.parse(event.data);
            } catch (e) {
              if (!eventId) {
                resolve({ type: "error", data: e, message: e.toString() });
              } else {
                options.onEvent({ type: 'error', data: e, message: e.toString() });
              }
            }
            if (parsedData && parsedData.eventType === 'RECORDED') {
              eventId = parsedData.eventId;
              try {
                url = url.indexOf('?') === -1 ? `${url}?__eventId=${eventId}` : `${url}&__eventId=${eventId}`;
                let res = await fetch(encodeURI(url), options);
                events.close();
                if (options.raw) {
                  resolve(res);
                }
                let json = await res.json();
                resolve(json);
              } catch (e) {
                resolve({ type: "error", data: e, message: e.toString() });
              }
            } else if (!eventId || !parsedData) {
              resolve({ type: "error", data: parsedData, message: 'bad response' });
            } else {
              options.onEvent(parsedData);
            }
          };
        });
      }
    }
    try {
      let res = await fetch(encodeURI(url), options);
      if (options.raw) {
        return res;
      }
      let json = await res.json();
      return json;
    } catch (e) {
      return { type: "error", data: e, message: e.toString() }
    }
  }

  get(params) {
    params = typeof (params) === "string" ? { url: params } : params;
    const { url, ...options } = params;
    options.method = 'GET';
    return this.fetch(url, options);
  }

  post(params) {
    const { url, ...options } = params;
    options.method = 'POST';
    return this.fetch(url, options);
  }

  put(params) {
    const { url, ...options } = params;
    options.method = 'PUT';
    return this.fetch(url, options);
  }

  patch(params) {
    const { url, ...options } = params;
    options.method = 'PATCH';
    return this.fetch(url, options);
  }

  delete(params) {
    params = typeof (params) === "string" ? { url: params } : params;
    const { url, ...options } = params;
    options.method = 'DELETE';
    return this.fetch(url, options);
  }
}

const ajax = new Ajax();

export default ajax;
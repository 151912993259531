import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

import styles from './styles';
import { forEach } from 'lodash';
import { Box, Stack, Typography } from '@mui/material';
import Notify from '../Notify';

class Dropzone extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.state = {
      extensions: ['image/jpeg', 'image/png'],
      maxSizeInBytes: 5500000,
    };
  }

  /**HANDLER */
  preventEvent(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  onChange(evt) {
    this.validateSend(evt.currentTarget);
  }

  onDrop(evt) {
    this.preventEvent(evt);
    this.validateSend(evt.dataTransfer);
  }

  validateImage(image) {
    const {extensions, maxSizeInBytes } = this.state;
    if (extensions.indexOf(image.type) === -1) {
      Notify.error("Fichier non valide");
      return false;
    }
    if (image.size > maxSizeInBytes) {
      Notify.error("Fichier trop volumineux (max. 5Mo)");
      return false;
    }
    return true;
  }

  /** SEND */
  validateSend(target) {
    if (this.props.onChange && target && target.files) {
      const files = target.files;
      forEach(files, (file, index) => {
        if (file.type.substr(0, "image/".length) === "image/") {
          if(this.validateImage(file)) {
            this.props.onChange(file);
          }
        }
      });
    }
  }

  render() {
    const { disabled, classes, width, height } = this.props;
    return (
      <Box>
        <Paper
          className={classes.input}
          style={{
            width: width,
            height: height,
          }}
        >
          <label
            onDrop={this.onDrop}
            onDragEnter={this.preventEvent}
            onDragOver={this.preventEvent}
            onDragLeave={this.preventEvent}
            className={classes.inputLabel}
          >
            <input
              accept="image/*"
              type="file"
              multiple
              style={{ display: 'none' }}
              onChange={this.onChange}
              disabled={disabled}
            />
            <Button
              component="span"
              className={`${classes.inputButton} ${disabled ? classes.inputButtonDisabled : ''}`}
              variant="text"
              disabled={disabled}
            >
              <Stack alignItems="center">
                <CameraAltIcon fontSize="large" color="secondary" />
                <Typography>Faites glisser et déposez les images ici. Ou cliquez sur la zone pour sélectionner les fichiers.</Typography>
                <Typography color='error'>Taille max 5Mo. Fichiers jpg ou png</Typography>
              </Stack>
            </Button>
          </label>
        </Paper>
      </Box>
    );
  }
}

Dropzone.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
}

export default withStyles(styles)(Dropzone);

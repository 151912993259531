import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import isNil from 'lodash/isNil';
import uniqueId from 'lodash/uniqueId';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import forEach from 'lodash/forEach';
import moment from 'moment';

import IconButton from '@mui/material/IconButton';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import Dialog from '@mui/material/Dialog';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import DataGrid from '../../components/Grids/DataGrid';
import DataGridToolbar from '../../components/Grids/DataGridToolbar';
import Notify from '../../components/base/Notify';

import { DEFAULT_PARAMS } from '../../components/Grids/DataGrid/constants';
import { EDITMODAL_MODES } from './constants';

import Ajax from '../../utils/Ajax';
import Download from '../../utils/Download';

import styles from './styles.js';

class EditableList extends React.Component {
  static defaultProps = {
    filterParams: [],
    actions: [],
    rowActions: [],
    filterActions: [],
    rowKey: 'id',
    filterId: 'id',
    searchKey: 'search',
    allowAdd: true,
    allowEdit: true,
    allowDelete: true,
    allowExport: true,
    disabledHashedState: false,
    requiredFields: [],
    defaultParams: {},
    routeBase: '',
    maxSize: 'md',
  }

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      columnsVisibility: {},
      rowCount: 0,
      page: 0,
      filters: {
        [props.searchKey]: '',
        ...DEFAULT_PARAMS,
        order: props.rowKey,
        ...props.defaultParams,
      },
      selectedRows: [],
      editPanelOpen: false,
      editItem: null,
      fetching: false,
      working: false, // Ajax request (add, edit...)
      ...this.getHashedState()
    };

    this.getColumns = this.getColumns.bind(this);
    this.toggleEditModal = this.toggleEditModal.bind(this);

    this.handleAction = this.handleAction.bind(this);
    this.handleSetSelectedRows = this.handleSetSelectedRows.bind(this);
    this.handleChangeFilters = this.handleChangeFilters.bind(this);
    this.handleClearFilters = this.handleClearFilters.bind(this);
    this.handleValidateEdition = this.handleValidateEdition.bind(this);

    this.fetchPage = debounce(this.fetchPage, 600).bind(this);
    this.applyEditItem = this.applyEditItem.bind(this);
    this.applyAddItem = this.applyAddItem.bind(this);
    this.applyDeleteSelection = this.applyDeleteSelection.bind(this);
    this.applyExport = this.applyExport.bind(this);
    this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
    this.setHashedState = this.setHashedState.bind(this);
  }

  getHashedState() {
    if (!this.props.disabledHashedState) {
      const urlParams = new URLSearchParams(window.location.search);
      const id = urlParams.get('hashedState');
      if (id) {
        try {
          const hash = window.sessionStorage.getItem(id);
          const state = JSON.parse(atob(hash));
          if (state) {
            this.hashedStateId = id;
          }
          return state;
        } catch (e) {
          return null;
        }
      }
    }
    return null;
  }

  setHashedState() {
    if (!this.props.disabledHashedState) {
      const { filters, page, columnsVisibility } = this.state;
      const hash = btoa(JSON.stringify({ filters, page, columnsVisibility }));
      const id = this.hashedStateId || btoa(uniqueId('hash_'));
      window.sessionStorage.setItem(id, hash);
      if (!this.hashedStateId) {
        var url = new URL(window.location.href);
        url.searchParams.set('hashedState', id);
        this.hashedStateId = id;
        window.history.replaceState(window.history.state, null, url);
      }
    }
  }

  componentDidMount() {
    const { page } = this.state;
    this.fetchPage(page);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.refreshToken !== this.props.refreshToken) {
      this.fetchPage(this.state.page);
    }
  }

  /**
   * GET
   */
  async fetchPage(nextPage = null) {
    const { route, routeBase, queryStringEnrich, filterParams } = this.props;
    this.setState(prevState => ({ fetching: true, page: isNil(nextPage) ? prevState.page : nextPage }), async () => {
      this.setHashedState();
      let queryStringEnrichValue = {};
      if (queryStringEnrich) {
        queryStringEnrichValue = queryStringEnrich(this.props, this.state);
      }
      const { filters, page } = this.state;
      const filtersFormatted = { ...filters };
      forEach(filterParams, filter => {
        if (!isNil(filtersFormatted[filter.key]) && filter.valueFormatter) {
          filtersFormatted[filter.key] = filter.valueFormatter(filtersFormatted[filter.key]);
        }
      });
      const offset = page * filters.limit;
      const res = await Ajax.get({
        url: `${routeBase}api/${route}`,
        token: true,
        queryString: {
          list: true,
          count: true,
          ...filtersFormatted,
          ...queryStringEnrichValue,
          offset,
        }
      });
      if (isEqual(this.state.filters, filters)) {
        const setState = { fetching: false };
        if (res.type === 'success') {
          setState.data = res.list;
          setState.rowCount = res.count;
          setState.filters = { ...this.state.filters, offset };
        } else {
          Notify.error(res.message);
        }
        this.setState(setState);
      }
    });
  }

  getColumns() {
    const { columns, allowEdit } = this.props;
    const { fetching } = this.state;
    const output = [...columns];
    if (allowEdit) {
      output.unshift({
        field: '__actions', headerName: '', width: 60, sortable: false, renderCell: ({row }) => (
          <Tooltip title="Editer la ligne">
             <span>
              <IconButton onClick={() => this.handleAction('edit', row)} disabled={fetching}>
                <ModeEditIcon />
              </IconButton>
            </span>
          </Tooltip>
        )
      });
    }
    return output;
  }

  async toggleEditModal(isOpen, item) {
    let editItem = item;
    let editPanelOpen = isOpen;
    if (editPanelOpen && !isNil(editItem)) {
      const { rowKey, route, routeBase } = this.props;
      const key = editItem[rowKey || 'id'];
      if (key) {
        const res = await Ajax.get({
          url: `${routeBase}api/${route}/${key}`,
          token: true,
        });
        if (res.type === 'success') {
          editItem = res.item;
        } else {
          editPanelOpen = false;
          Notify.error(res.message);
        }
      }
    }
    this.setState({ editPanelOpen, editItem });
  }

  /**
   * HANDLES
   */
  handleChangeFilters(filters) {
    this.setState(prev => {
      const newFilters = { ...prev.filters, ...filters };
      forEach(newFilters, (value, key) => {
        if (value === undefined) {
          delete newFilters[key];
        }
      });
      return { filters: newFilters };
    }, () => this.fetchPage(0));
  }

  handleVisibilityChange(columnsVisibility) {
    this.setState({ columnsVisibility }, this.setHashedState);
  }

  handleClearFilters() {
    this.setState({ filters: { ...DEFAULT_PARAMS, order: this.props.rowKey } }, () => this.fetchPage(0));
  }

  handleAction(action, params) {
    if (action === 'add') {
      return this.toggleEditModal(true);
    } else if (action === 'edit' && !isNil(params)) {
      return this.toggleEditModal(true, params);
    } else if (action === 'delete' && params.length > 0) {
      return this.applyDeleteSelection(params);
    } else if (action === 'export') {
      return this.applyExport(params);
    }
  }

  handleSetSelectedRows(selectedRows) {
    this.setState({ selectedRows })
  }

  handleValidateEdition(body, isNew) {
    if (isNew) {
      this.applyAddItem(body);
    } else {
      this.applyEditItem(body);
    }
  }

  /**
   * AJAX
   */
  async applyEditItem(body) {
    const { onApplyChange } = this.props;
    this.setState({ working: true });
    const { route, rowKey, routeBase } = this.props;
    const { editItem } = this.state;
    const id = body[rowKey];
    const res = await Ajax.patch({ url: `${routeBase}api/${route}/${id}`, body, token: true });
    if (res.type === 'success') {
      Notify.success(`{${id}} modifié avec succès`);
      this.toggleEditModal(false, editItem);
      this.fetchPage(0);
      if (onApplyChange) {
        onApplyChange();
      }
    } else {
      Notify.error(res.message);
    }
    this.setState({ working: false });
  }

  async applyAddItem(body) {
    const { onApplyChange } = this.props;
    this.setState({ working: true });
    const { route, routeBase } = this.props;
    const res = await Ajax.post({ url: `${routeBase}api/${route}`, body, token: true });
    if (res.type === 'success') {
      Notify.success('Element ajouté avec succès');
      this.toggleEditModal(false);
      this.fetchPage(0);
      if (onApplyChange) {
        onApplyChange();
      }
    } else {
      Notify.error(res.message);
    }
    this.setState({ working: false });
  }

  async applyDeleteSelection(selectedRows) {
    const { onApplyChange, rowKey } = this.props;
    const ids = selectedRows.map(row => row[rowKey]);
    this.setState({ working: true });
    const { route, routeBase } = this.props;
    const res = await Ajax.delete({ url: `${routeBase}api/${route}`, queryString: { id: ids }, token: true });
    if (res.type === 'success') {
      const multi = ids.length > 1 ? 's' : '';
      Notify.success(`Element${multi} supprimé${multi} avec succès`);
      this.fetchPage(0);
      if (onApplyChange) {
        onApplyChange();
      }
    } else {
      Notify.error(res.message);
    }
    this.setState({ working: false });
  }

  async applyExport(params) {
    this.setState({ working: true });
    const { route, filterId, rowKey, routeBase } = this.props;
    let queryString = {
      file: params.type,
      separator: params.separator,
    };
    if (params.rows) {
      queryString[filterId] = params.rows.map(row => row[rowKey]);
      queryString.limit = -1;
    } else {
      const { filters } = this.state;
      queryString = {
        ...queryString,
        ...filters,
        limit: -1,
      };
    }
    const res = await Ajax.get({
      url: `${routeBase}api/${route}-export`,
      queryString,
      token: true
    });
    if (res.type === 'success' && res.content && res.content !== 'false') {
      Download(res.content, `${routeBase}export-${route}-${moment().format('YYYY-MM-DD_HH-mm-ss')}.${params.type.toLowerCase()}`, params.type);
      Notify.success('Opération effectuée avec succès');
    } else {
      Notify.error(res.message || 'Erreur lors de la création du fichier');
    }
    this.setState({ working: false });
  }

  /**
   * RENDERS
   */
  renderGrid(columns) {
    const { classes, rowKey, hideSelectionCheckbox } = this.props;
    const { data, rowCount, filters, selectedRows, fetching, columnsVisibility } = this.state;
    return (
      <div className={classes.listContent}>
        <DataGrid
          data={data}
          rowKey={rowKey}
          columns={columns}
          columnsVisibility={columnsVisibility}
          count={rowCount}
          params={filters}
          selectedRows={selectedRows}
          loading={fetching}
          onPageChange={p => this.fetchPage(p)}
          onChangeParams={this.handleChangeFilters}
          onChangeSelectedRows={!hideSelectionCheckbox ? this.handleSetSelectedRows : undefined}
        />
      </div>
    );
  }

  renderEditPanel() {
    const { EditModalComponent, rowKey, requiredFields, options, dialogProps, maxSize } = this.props;
    const { editPanelOpen, editItem, working } = this.state;

    if (!EditModalComponent) {
      return null;
    }

    return (
      <Dialog
        open={editPanelOpen}
        maxWidth={maxSize}
        disableEscapeKeyDown
        fullWidth
        {...dialogProps}
      >
        <EditModalComponent
          item={editItem}
          rowKey={rowKey}
          requiredFields={requiredFields}
          mode={!isNil(editItem) ? EDITMODAL_MODES.EDIT : EDITMODAL_MODES.ADD}
          working={working}
          options={options}
          onCancel={() => this.toggleEditModal(false, editItem)}
          onValidate={this.handleValidateEdition}
        />
      </Dialog>
    );
  }

  render() {
    const { 
      classes,
      allowAdd,
      allowDelete,
      allowExport,
      filterParams,
      actions,
      rowActions,
      filterActions,
      searchKey,
      searchLabel,
      searchPlaceholder,
      hideRowActions,
      title,
    } = this.props;
    const { rowCount, filters, selectedRows, columnsVisibility } = this.state;
    const columns = this.getColumns();
    return (
      <div className={classes.wrapper}>
        <Typography variant="h4">{title}</Typography>
        <DataGridToolbar
          rowCount={rowCount}
          filters={filters}
          selectedRows={selectedRows}
          filterParams={filterParams}
          onAction={this.handleAction}
          onChangeFilters={this.handleChangeFilters}
          onClearFilters={this.handleClearFilters}
          searchKey={searchKey}
          searchLabel={searchLabel}
          searchPlaceholder={searchPlaceholder}
          additionnalActions={actions}
          additionnalRowActions={rowActions}
          additionnalFilterActions={filterActions}
          allowAdd={allowAdd}
          allowDelete={allowDelete}
          allowExport={allowExport}
          hideRowActions={hideRowActions}
          columns={columns}
          columnsVisibility={columnsVisibility}
          onVisibilityChange={this.handleVisibilityChange}
        />
        <div className={classes.listWrapper}>
          {this.renderGrid(columns)}
        </div>
        {this.renderEditPanel()}
      </div>
    );
  }
}

EditableList.propTypes = {
  classes: PropTypes.object.isRequired,
  // Main
  route: PropTypes.string.isRequired,
  routeBase: PropTypes.string,
  rowKey: PropTypes.string.isRequired,
  filterId: PropTypes.string,
  columns: PropTypes.array.isRequired,
  filterParams: PropTypes.array,
  EditModalComponent: PropTypes.object,
  requiredFields: PropTypes.oneOfType([PropTypes.array, PropTypes.object]), // Either ['fieldname'] or { [EDITMODAL_MODES.ADD]: ['fieldname], [EDITMODAL_MODES.EDIT]: ['fieldname']}
  actions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.func,
  ]),
  rowActions: PropTypes.array,
  filterActions: PropTypes.array,
  hideSelectionCheckbox: PropTypes.bool,
  defaultParams: PropTypes.object,
  dialogProps: PropTypes.object,
  // Restrictions
  allowAdd: PropTypes.bool,
  allowEdit: PropTypes.bool,
  allowDelete: PropTypes.bool,
  allowExport: PropTypes.bool,
  // Search
  searchKey: PropTypes.string,
  searchLabel: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  disabledHashedState: PropTypes.bool,
  // Others
  options: PropTypes.object,
  hideRowActions: PropTypes.bool,
  refreshToken: PropTypes.number,
  title: PropTypes.string,
  maxSize: PropTypes.string,
  // Func
  queryStringEnrich: PropTypes.func, // return object with queryString values
  onApplyChange: PropTypes.func,
};

export default withStyles(styles)(EditableList);

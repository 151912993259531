import React from 'react';
import PropTypes from 'prop-types';
import forEach from 'lodash/forEach';
import isNil from 'lodash/isNil';
import isArray from 'lodash/isArray';
import uniqueId from 'lodash/uniqueId';

import MGrid from '@mui/material/Grid';

import { GetChild } from './utils';
import Item from './Item';

class Grid extends React.PureComponent {
  static Item = Item;
  static defaultProps = {
    className: '',
    itemClassName: '',
    spacing: 1,
    justify: 'flex-start',
    alignItems: 'flex-start',
    alignContent: 'stretch',
    direction: 'row',
    wrap: 'wrap',
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: false,
  };

  render() {
    const list = [];
    this.parseList(list, [this.props.children]);
    return (
      <MGrid
        className={`grid grid-spacing-${this.props.spacing} ${this.props.className}`}
        container
        spacing={this.props.spacing}
        justify={this.props.justify}
        direction={this.props.direction}
        alignItems={this.props.alignItems}
        alignContent={this.props.alignContent}
        wrap={this.props.wrap}
      >
        {list}
      </MGrid>
    );
  }

  parseList(list, children) {
    forEach(children, child => {
      if (!isNil(child)) {
        if (React.isValidElement(child)) {
          if (child.type === React.Fragment) {
            if (!isNil(child.props.children)) {
              this.parseList(list, [child.props.children]);
            }
          } else if (child.type === Item) {
            list.push(child);
          } else {
            list.push(GetChild(child.key, child, this.props));
          }
        } else if (isArray(child)) {
          this.parseList(list, child);
        } else if (typeof (child) === "string") {
          list.push(GetChild(uniqueId('GridItem-'), child, this.props));
        }
      }
    });
  }
}

Grid.propTypes = {
  className: PropTypes.string,
  itemClassName: PropTypes.string,
  spacing: PropTypes.number,
  justify: PropTypes.oneOf(['flex-start', 'center', 'flex-end', 'space-between', 'space-around', 'space-evenly']),
  direction: PropTypes.oneOf(['row', 'row-reverse', 'column', 'column-reverse']),
  alignItems: PropTypes.oneOf(['flex-start', 'center', 'flex-end', 'stretch', 'baseline']),
  alignContent: PropTypes.oneOf(['stretch', 'center', 'flex-start', 'flex-end', 'space-between', 'space-around']),
  wrap: PropTypes.oneOf(['wrap', 'nowrap', 'wrap-reverse']),
  xs: PropTypes.oneOf([false, 'auto', true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  sm: PropTypes.oneOf([false, 'auto', true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  md: PropTypes.oneOf([false, 'auto', true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  lg: PropTypes.oneOf([false, 'auto', true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  xl: PropTypes.oneOf([false, 'auto', true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
}

export default Grid;
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import styles from './styles.js';
import Dropzone from '../../components/base/Dropzone/';
import Ajax from '../../utils/Ajax/index.js';
import { Box, CircularProgress, IconButton, Pagination } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Notify from '../../components/base/Notify/index.js';

class Gallery extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      medias: [],
      page: 1,
      pageCount: 0,
      mediaCount: 0,
      itemPerPage: 25,
      loading: false,
      ajaxId: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.deleteMedia = this.deleteMedia.bind(this);
    this.handlePage = this.handlePage.bind(this);
    this.fetchPage = this.fetchPage.bind(this);
    this.getUniqId = this.getUniqId.bind(this);
  }

  async componentDidMount() {
    this.fetchPage(1);
  }

  async fetchPage(page) {
    const { itemPerPage } = this.state;
    const uniqId = this.getUniqId();
    this.setState({ page, ajaxId: uniqId, loading: true }, async () => {
      const res = await Ajax.get(
        {
          url: 'api/gallery',
          queryString: {
            list: true,
            count: true,
            offset: page ? (page - 1) * itemPerPage : 0,
          },
          token: true,
        }
      );
      if (uniqId === this.state.ajaxId) {
        if (res.type === "success") {
          const { list, count } = res;
          const pageCount = Math.ceil(count / itemPerPage);
          this.setState({ medias: list, mediaCount: count, pageCount, loading: false });
        } else {
          Notify.error(`${res.message}`);
        }
      }
    });
  }

  handleChange(file) {
    let uniqId = this.getUniqId();
    let filename = `file-${uniqId}`;
    this.postFile(file, filename);
  }

  handlePage = (event, page) => {
    this.fetchPage(page)
  };

  async postFile(file, filename) {
    const res = await Ajax.post(
      {
        url: 'api/gallery',
        body: {
          file,
          name: filename
        },
        token: true,
      }
    );
    if (res.type === "success") {
      this.fetchPage(1);
    } else {
      Notify.error(`${res.message}`);
    }
  }

  async deleteMedia(id) {
    const { page } = this.state;
    const res = await Ajax.delete(
      {
        url: 'api/gallery',
        queryString: {
          id,
        },
        token: true,
      }
    );
    if (res.type === "success") {
      this.fetchPage(page);
    } else {
      Notify.error(`${res.error} (${res.events})`, { delay: 10 });
    }
  }

  getUniqId() {
    let uniqueInt = new Date().getTime();
    return Math.floor(Math.random() * uniqueInt) + uniqueInt;
  }

  renderImageList() {
    const { medias, loading } = this.state;
    const { classes } = this.props;
    if (medias && medias.length > 0) {
      return (
        <Box className={classes.imageListWrapper}>
          {loading ? (
            <CircularProgress
              size={80}
              sx={{
                position: 'absolute',
                margin: 'auto',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                zIndex: 5,
              }}
            />
          ) : null}
          {medias.map((file) => (
            this.renderImagePreview(file)
          ))}
        </Box>
      )
    }
    return null;
  }

  renderImagePreview(file) {
    const { loading } = this.state;
    const { classes, getUrl, canDelete } = this.props;
    return (
      <Box
        key={file.id}
        id={file.id}
        onClick={getUrl ? () => getUrl(file.id, file.url) : null}
        className={classes.imageContainer}
        sx={{ cursor: canDelete ? 'default' : 'pointer' }}
      >
        <LazyLoadImage
          src={file.url}
          alt={'/' + file.name}
          effect="blur"
          className={classes.imageItem}
        />
        {canDelete && !loading ? (
          <IconButton
            aria-label="close"
            onClick={() => this.deleteMedia(file.id)}
            className={classes.deleteBtn}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </Box>
    )
  }

  renderPaginate() {
    const { page, pageCount } = this.state;
    return (
      <Box sx={{
        display: 'flex',
        justifyContent: { xs: 'center', md: 'flex-end' },
        mt: 2,
      }}>
        <Pagination size='small'
          count={pageCount}
          color="primary"
          page={page}
          onChange={this.handlePage}
        />
      </Box>
    )
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.wrapper}>
        <Dropzone width="100%" height={250} onChange={this.handleChange} />
        {this.renderImageList()}
        {this.renderPaginate()}
      </div>
    );
  }
}

Gallery.defaultProps = {
  canDelete: true,
  getUrl: null,
}

Gallery.propTypes = {
  classes: PropTypes.object.isRequired,
  redirect: PropTypes.func,
  match: PropTypes.object,
  user: PropTypes.object,
  getUrl: PropTypes.func,
  canDelete: PropTypes.bool,
}

export default withStyles(styles)(Gallery);

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { List, ListItem, ListItemText, Typography } from '@mui/material';
import { Link } from 'react-router-dom';


class EventList extends React.Component {

  render() {
    const { events, user } = this.props;
    if (events && events.length <= 0) {
      return (
        <Typography variant='body2' component="p"> Aucun tournoi.</Typography>
      )
    }
    return (
      <List dense disablePadding>
        {events.map(event => {
          const date = moment(`${event.event_date} ${event.event_hour}`).format('lll');
          const shop = user.shops.find(shop => parseInt(shop.id, 10) === event.shop_id)?.name || 'Non définie';
          return (<ListItem key={event.id}>
            <Link style={{ textDecoration: "none" }} to={`/event/view/${event.id}`}>
              <ListItemText
                sx={{ margin: 0 }}
                primary={event.name}
                primaryTypographyProps={{ sx: { textDecoration: 'underline', color: 'primary.main', fontSize: '1rem' } }}
                secondary={`Boutique: ${shop}, Date: ${date}`}
                secondaryTypographyProps={{ sx: { fontSize: '0.8rem' } }}
              />
            </Link>
          </ListItem>)
        }
        )}
      </List>
    );
  }
}

EventList.propTypes = {
  match: PropTypes.object,
  user: PropTypes.object,
  events: PropTypes.array,
}

export default EventList;

import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { forEach } from 'lodash';
class Profit extends React.Component {

  render() {
    const { events } = this.props;
    const intlNumber = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' });

    const aggregedDataMap = {};
    forEach(events, event => {
      const name = event.name;
      aggregedDataMap[name] = event.real_marge;
    });

    let aggregedData = [];
    forEach(Object.keys(aggregedDataMap), key => {
      aggregedData.push({
        key,
        value: aggregedDataMap[key]
      });
    });

    const data = {
      labels: aggregedData.map(s => s.key),
      datasets: [
        {
          label: 'Marge',
          data: aggregedData.map(s => s.value),
          backgroundColor: function (value) {
            return value.dataset.data[value.dataIndex] > 0 ? 'rgb(231, 170, 13)' : 'rgb(255, 55, 55)';
          },
          // backgroundColor: 'rgb(231, 170, 13)',
        },
      ],
    };

    const options = {
      animation: false,
      legend: { display: true },
      maintainAspectRatio: true,
      responsive: true,
      responsiveAnimationDuration: 0,
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
            callback: function (value, index, values) {
              return intlNumber.format(value);
            }
          }
        }],
        xAxes: [{
          ticks: {
            callback: function (value, index, values) {
              return '';
            }
          }
        }]
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            var label = data.datasets[tooltipItem.datasetIndex].label || '';

            if (label) {
              label += ': ';
            }
            label += intlNumber.format(tooltipItem.yLabel);
            return label;
          }
        }
      }
    }

    return (
     <Bar height={90} data={data} options={options} />
    );
  }
}

Profit.propTypes = {
  match: PropTypes.object,
  user: PropTypes.object,
  events: PropTypes.array,
}

export default Profit;

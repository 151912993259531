import React from 'react';
import { withStyles } from '@mui/styles';
import { Tooltip, Box, Stack, Divider, Checkbox } from '@mui/material';
import EditModalWrapper from '../../../containers/EditableList/EditModalWrapper';
import Ajax from '../../../utils/Ajax';
import _debounce from 'lodash/debounce';

import styles from './styles';
import Notify from '../../../components/base/Notify';
import { isEmpty } from 'lodash';

class EditModal extends EditModalWrapper {
  constructor(props) {
    super(props);
    this.getProductsOptionsLabel = this.getProductsOptionsLabel.bind(this);
    this.getEventsOptionsLabel = this.getEventsOptionsLabel.bind(this);

    if (props.item && !props.item.event_id && props.options && props.options.event_id && props.options.event_name) {
      this.state.form.event_id = props.options.event_id;
      this.state.form.event_name = props.options.event_name;
    }
  }

  extendState() {
    return { hasStock: 1, events: [], products: [], loadingEvent: false, loadingProduct: false };
  }

  async componentDidMount() {
    const listEvents = await this.getEvents('');
    const listProducts = await this.getProducts('');

    this.setState({ events: listEvents, products: listProducts });
  }

  async getEvents(value) {
    this.setState({ loadingEvent: true });
    const res = await Ajax.get(
      {
        url: 'api/events',
        queryString: {
          list: true,
          limit: 50,
          search: value,
        },
        token: true,
      }
    );
    this.setState({ loadingEvent: false });
    if (res.type === "success") {
      return res.list;
    } else {
      Notify.error(`${res.message}`);
    }
    return [];
  }

  async getProducts(value) {
    const { userDefaultShop } = this.props.options;
    this.setState({ loadingProduct: true });
    const res = await Ajax.get(
      {
        url: 'api/stock',
        queryString: {
          list: true,
          limit: 50,
          search: value,
          order: 'name',
          hasStock: this.state.hasStock,
          shopId: this.state.form.shop_id || userDefaultShop.id
        },
        token: true,
      }
    );
    this.setState({ loadingProduct: false });
    if (res.type === "success") {
      return res.list;
    } else {
      Notify.error(`${res.message}`);
    }
    return [];
  }

  getEventsOptions() {
    const { events, form } = this.state;
    const result = events.map(e => e.id);
    if (form && form.event_id && !result.find(id => id === form.event_id)) {
      result.unshift(form.event_id);
    }
    return result;
  }

  getEventsOptionsLabel(eventId) {
    const { events, form } = this.state;
    if (form && form.event_id && form.event_id === eventId) {
      return `${form.event_name}`;
    }
    const findOption = events.find(e => e.id === eventId);
    if (findOption) {
      return `${findOption.name}`;
    }
    return '';
  }

  getProductsOptions() {
    const { products, form } = this.state;
    const result = products.map(p => p.id);
    if (form && form.product_id && !result.find(id => id === form.product_id)) {
      result.unshift(form.product_id);
    }
    return result;
  }

  getProductsOptionsLabel(productId) {
    const { products, form } = this.state;
    if (form && form.product_id && form.product_id === productId) {
      return `[${form.product_id}] (${form.stock}) ${form.product_name}`;
    }
    const findOption = products.find(p => p.id === productId);
    if (findOption) {
      return `[${findOption.id}] (${findOption.quantity}) ${findOption.name}`;
    }
    return '';
  }

  handleChangeSelect(name, value) {
    if (name) {
      let refreshProduct = false;
      this.setState(prev => {
        const form = {...prev.form};
        if (name === 'product_id') {
          const { products } = this.state;
          const product = value ? products.find(p => p.id === value) : null;
          form.product_name = product ? product.name : '';
          form.stock = product ? product.quantity : '';
          form.price_unit = product ? product.price_catalog || product.price_wholesale || '' : '';
        } else if(name === 'event_id') {
          const { events } = this.state;
          const event = value ? events.find(p => p.id === value) : null;
          form.event_name = event ? event.name : '';
          form.shop_id = event ? event.shop_id : '';
          if (form.shop_id && form.shop_id !== prev.form.shop_id) {
            refreshProduct = true;
          }
        }
        form[name] = value;
        return { form, missingFields: [], invalidFields: [] };
      }, async () => {
        if(refreshProduct) {
          const listProducts = await this.getProducts(this.state.form.product_name);
          this.setState(prev => {
            const product = value ? listProducts.find(p => p.id === this.state.form.product_id) : null;
            const form = { ...prev.form };
            if (product) {
              form.stock = product ? product.quantity : '';
            }
            return { products: listProducts, form };
          });
        }
      });
    }
  }

  renderContent() {
    const { item } = this.props;
    const { loadingEvent, loadingProduct } = this.state;
    return (
      <Stack
        direction="row"
        justifyContent="space-around"
        divider={<Divider orientation="vertical" flexItem />}
      >
        <Box sx={{ width: '30%' }}>
          <Stack direction="column" spacing={2}>
            {!isEmpty(item) && this.renderTextField({ key: 'id', label: 'ID', disabled: true })}
            {this.renderSelect({
              key: 'event_id',
              label: 'Évènement',
              placeholder: 'Évènement',
              options: this.getEventsOptions(),
              multiple: false,
              loading: loadingEvent,
              disabled: (!isEmpty(item) && item.destock > 0) ? true : false,
              helperText: 'Choisir dans la liste ou tapez le terme de recherche',
              getOptionLabel: this.getEventsOptionsLabel,
              onInputChange: _debounce(async evt => {
                const { value } = evt.target;
                const list = await this.getEvents(value);
                this.setState({ events: list });
              }, 300),
              filterOptions: options => options
            })}
          </Stack>
        </Box>
        <Box sx={{ width: '60%' }}>
          <Stack direction="column" spacing={2}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Box>
                  <Tooltip title="Produits en stock">
                    <Checkbox checked={!!this.state.hasStock} onChange={evt => {
                      this.setState({ hasStock: evt.target.checked ? 1 : 0}, async () => {
                        const list = await this.getProducts('');
                        this.setState({ products: list });
                      });
                    }} />
                  </Tooltip>
              </Box>
              {this.renderSelect({
                BoxProps: { width: '100%'},
                key: 'product_id',
                label: 'Produit',
                placeholder: 'Produit',
                options: this.getProductsOptions(),
                multiple: false,
                loading: loadingProduct,
                helperText: 'Choisir dans la liste ou tapez le terme de recherche',
                disabled: !isEmpty(item) && item.destock > 0 ? true : false,
                getOptionLabel: this.getProductsOptionsLabel,
                onInputChange: _debounce(async evt => {
                  const { value } = evt.target;
                  const list = await this.getProducts(value);
                  this.setState({ products: list });
                }, 300),
                filterOptions: options => options
              })}
            </Stack>
            {this.renderTextField({ key: 'quantity', label: 'Quantité prévue', type: 'number' })}
            {this.renderTextField({ key: 'price_unit', label: "Prix unitaire d'achat", type: 'number' })}
            {this.renderTextField({ key: 'dispatch_comment', label: 'Commentaire', multiline: true })}
          </Stack>
        </Box>
      </Stack>
    );
  }
}

export default withStyles(styles)(EditModal);

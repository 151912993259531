const APP_NAME = 'Parkage - Gestion des Évènements';
const ENV = process.env.NODE_ENV === 'development' ? 'dev' : 'prod';

const PUBLIC_URL = process.env.PUBLIC_URL;
const DEFAULT_LOADER_IMG = 'loader.png';

const LOGGED_REDIRECTION = '/dashboard/';
const UNLOGGED_REDIRECTION = '/';

const DEFAULT_USER = { id: '', actions: {} };
const REFRESH_INTERVAL = 3600 * 1000; // toutes les heures

const DRAWER_WIDTH = 280;

const TOKEN_KEY = 'Token';
const SHOP_KEY = 'Shop';

const PROTOCOL = ENV === 'dev' ? 'http:' : 'https:';
const HOST = ENV === 'dev' ? 'localhost' : 'event.parkage.com';
const PORT = ENV === 'dev' ? '7515' : '';

const EXPORT_FILE_TYPES = ['CSV', 'JSON'];
const EXPORT_ALLOWED_SEPARATORS = [',', ';', ' '];

const CONFIG = {
  APP_NAME,
  ENV,
  PUBLIC_URL,
  DEFAULT_LOADER_IMG,
  LOGGED_REDIRECTION,
  UNLOGGED_REDIRECTION,
  REFRESH_INTERVAL,
  DRAWER_WIDTH,
  DEFAULT_USER,
  TOKEN_KEY,
  SHOP_KEY,
  PROTOCOL,
  HOST,
  PORT,
  EXPORT_FILE_TYPES,
  EXPORT_ALLOWED_SEPARATORS
};

export default CONFIG;

import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import PropTypes from 'prop-types';

class Confirm extends React.PureComponent {

  render() {
    const { open, title, message, onValidate, validate, onClickAway, cancel, dialogProps } = this.props;
    return (
      <Dialog
        {...dialogProps}
        open={open}
        onClose={onClickAway}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          {title}
        </DialogTitle>
        <DialogContent>
          {typeof message === 'string' ? (
            <DialogContentText>
              {message}
            </DialogContentText>
          ) : message}
        </DialogContent>
        <DialogActions>
          <Button onClick={onValidate} autoFocus>{validate}</Button>
          <Button onClick={onClickAway}>{cancel}</Button>          
        </DialogActions>
      </Dialog>
    );
  }
}

Confirm.defaultProps = {
  cancel: 'Annuler',
  validate: 'OK',
  open: true,
  dialogProps: {},
}

Confirm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClickAway: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
  title: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  cancel: PropTypes.string,
  validate: PropTypes.string,
  dialogProps: PropTypes.object,
}

export default Confirm;
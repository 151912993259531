import React from 'react';
import { GridOverlay } from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress'

/**
 * DataGrid LoadingOverlay
 */
class DataGridLoadingOverlay extends React.PureComponent {
  render() {
    return (
      <GridOverlay>
        <div style={{ position: 'absolute', top: 0, width: '100%' }}>
          <LinearProgress />
        </div>
      </GridOverlay>
    );
  }
}

DataGridLoadingOverlay.propTypes = {}

export default DataGridLoadingOverlay;
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import LinearProgress from '@mui/material/LinearProgress';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Box, FormControl, FormControlLabel, Switch, Typography } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'
import ClearIcon from '@mui/icons-material/Clear';

import AutoSelect from '../../components/base/AutoSelect';

import { EDITMODAL_MODES } from './constants';

/**
 * EditModalWrapper : Classe parent pour les EditModal de chaque page d'edition
 * utilisant le composant EditableList
 */
class EditModalWrapper extends React.Component {
  static defaultProps = {
    item: {},
    requiredFields: [],
  }

  constructor(props) {
    super(props);

    const extendForm = props.item ? props.item : {};
    this.state = {
      form: {
        ...extendForm,
      },
      missingFields: [],
      invalidFields: [],
      ...this.extendState(),
    };

    this.extendState = this.extendState.bind(this);
    this.checkMissing = this.checkMissing.bind(this);
    this.compileForm = this.compileForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.handleValidate = this.handleValidate.bind(this);
    this.handleSwitch = this.handleSwitch.bind(this);
    this.handleChangeDatePicker = this.handleChangeDatePicker.bind(this);
    this.handleChangeTimePicker = this.handleChangeTimePicker.bind(this);
    this.handleQuill = this.handleQuill.bind(this);
  }

  // Override : ajoute des paramètres au state @returns {object}
  extendState() { return {}; }

  /**
   * Permet de vérifier que tous les champs obligatoires du formulaire state @form
   * sont présents dans l'array de la props @requiredFields 
   * @returns {array} ['field', 'field'];
   */
  checkMissing() {
    const missingFields = [];
    const { requiredFields, mode } = this.props;
    const { form } = this.state;

    let requiredFieldList = requiredFields || [];
    if (typeof (requiredFields) === 'object' && !isEmpty(requiredFields) && requiredFields[mode]) {
      requiredFieldList = requiredFields[mode] || [];
    }
    if (requiredFieldList.length > 0) {
      forEach(requiredFieldList, field => {
        if (!form[field]) {
          missingFields.push(field);
        }
      });
    }
    return missingFields;
  }

  /**
   * Vérifie la validité du formulaire avant d'envoyer au back
   * @returns {boolean|array} true = valide, array : liste des champs invalide
   */
  checkValidity() {
    return true;
  }

  // Override > effectue les modifications du form pour le formater
  // pour le back
  compileForm() {
    return this.state.form;
  }

  /**
   * HANDLES
   */
  /**
   * Fonction générique permettant de modifier la state @form {object} avec un combo nom & valeur
   * Callback à deux possibilités d'arguments :
   * 1 argument @evt {object} => provient d'un input avec { target: { value: 'VAL', name: 'NAME' } };
   * 2 arguments @name {string} & @value {any} => appelé manuellement 
   */
  handleChange() {
    let name = null;
    let value = null;
    if (arguments.length === 1 && arguments[0].target) {
      // Valid for Textfield and Select
      const target = arguments[0].target;
      name = target.name;
      value = target.value;
    } else if (arguments.length === 2) {
      // Generic change
      name = arguments[0];
      value = arguments[1];
    }
    if (name) {
      this.setState(prev => ({ form: { ...prev.form, [name]: value }, missingFields: [], invalidFields: [] }));
    }
  }

  handleChangeSelect(name, value) {
    if (name) {
      this.setState(prev => {
        return ({ form: { ...prev.form, [name]: value }, missingFields: [], invalidFields: [] })
      });
    }
  }

  handleChangeDatePicker = key => (event) => {
    if (key) {
      this.setState(prev => {
        return ({ form: { ...prev.form, [key]: event }, missingFields: [], invalidFields: [] })
      });
    }
  }

  handleChangeTimePicker = key => (event) => {
    if (key) {
      this.setState(prev => {
        return ({ form: { ...prev.form, [key]: event }, missingFields: [], invalidFields: [] })
      });
    }
  }

  handleSwitch = key => (event) => {
    if (key) {
      this.setState(prev => {
        return ({ form: { ...prev.form, [key]: event.target.checked ? 1 : 0 }, missingFields: [], invalidFields: [] })
      });
    }
  }

  handleQuill = key => (event) => {
    if (key) {
      this.setState(prev => {
        return ({ form: { ...prev.form, [key]: event }, missingFields: [], invalidFields: [] })
      });
    }
  }

  /**
   * Lancé au clic du bouton Valider, lance une vérification des
   * champs requis de la props @requiredFields {array}
   * @returns utilisé seulement pour bloquer la suite de la fonction
   */
  handleValidate() {
    const missingFields = this.checkMissing();
    if (missingFields.length > 0) {
      return this.setState({ missingFields });
    }
    const validity = this.checkValidity();
    if (validity !== true) {
      const invalidFields = Array.isArray(validity) ? validity : [];
      return this.setState({ invalidFields });
    }
    const form = this.compileForm();
    this.props.onValidate(form, this.props.mode === EDITMODAL_MODES.ADD);
    this.setState({ missingFields: [], invalidFields: [] });
  }

  /**
   * RENDER
   */
  /**
   * Textfield générique
   * @param {object} param* objet de paramètres
   * @param {string} param.key* la clé/colonne de l'item
   * @param {string} param.label le label a afficher dans le textfield
   * @param {string} param.variant le variant du textfield
   * @param {string} param.type le type du textfield (default 'text')
   * @param {bool} param.disabled désactive le champs
   * @param {bool} param.multiline passe le champs en textarea
   * @returns {Node} le noeud react du textfield
   */
  renderTextField({ key, label, type, variant, disabled, helperText, multiline, placeholder }) {
    const { requiredFields, mode } = this.props;
    const { form, missingFields, invalidFields } = this.state;
    const isDisabled = disabled || false;
    const isMultiline = multiline || false;
    let isRequired = Array.isArray(requiredFields) ? requiredFields.includes(key) : false;
    if (requiredFields[mode]) {
      isRequired = requiredFields[mode].includes(key);
    }
    return (
      <TextField
        name={key}
        placeholder={placeholder}
        value={isNil(form[key]) ? '' : form[key]}
        label={label || key}
        type={type || "text"}
        variant={variant || "standard"}
        disabled={isDisabled}
        multiline={isMultiline}
        rows={isMultiline ? 4 : 1}
        error={missingFields.includes(key) || invalidFields.includes(key)}
        onChange={this.handleChange}
        required={isRequired}
        autoComplete={type === 'password' ? 'new-password' : 'off'}
        helperText={helperText || null}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton size="small" onClick={() => this.handleChange(key, '')} disabled={isDisabled}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        fullWidth
      />
    );
  }

  renderQuill({ key, label }) {
    const { form } = this.state;
    return (
      <FormControl sx={{ width: 'inherit', height: 'inherit' }}>
        <Typography>{label || key}</Typography>
        <ReactQuill
          theme='snow'
          value={form[key] || ''}
          onChange={this.handleQuill(key)}
          className='quillOverride'
          modules={{
            toolbar: [
              [{ 'header': [] }],
              ['bold', 'italic', 'underline', 'strike'],
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
              ['link'], ['clean'], [{ 'color': [] }],
              [{ align: '' }, { align: 'center' }, { align: 'right' }],
            ],
            clipboard: {
              // toggle to add extra line breaks when pasting HTML:
              matchVisual: false,
            },
          }}
          formats={[
            'header', 'color',
            'bold', 'italic', 'underline', 'strike',
            'list', 'bullet', 'indent',
            'link', 'align',
          ]}
        />
      </FormControl>
    );
  }

  renderSwitch({ key, label, disabled }) {
    const { form } = this.state;
    const isDisabled = disabled || false;
    const isChecked = form[key] === 1 ? true : false;
    return (
      <FormControlLabel
        key={key}
        value={form[key] || 0}
        control={<Switch color="primary" />}
        label={label || key}
        labelPlacement="end"
        disabled={isDisabled}
        onChange={this.handleSwitch(key)}
        checked={isChecked}
        sx={{ width: 'fit-content' }}
      />
    );
  }

  renderImage({ key, label }) {
    const { form } = this.state;
    return (
      <Box>
        <Typography>{label || ''}</Typography>
        {form[key] && form[key] !== '' ? (
          <img
            src={form[key] || ''}
            alt={form[key] || ''}
            style={{
              display: 'block',
              width: '240px',
              height: '300px',
              margin: 'auto',
              objectFit: 'cover'
            }}
          />
        ) : null}
      </Box>
    );
  }

  /**
 * DatePicker générique
 * @param {object} param* objet de paramètres
 * @param {string} param.key* la clé/colonne de l'item
 * @param {string} param.label le label a afficher dans le textfield
 * @param {bool} param.disabled désactive le champs
 * @returns {Node} le noeud react du textfield
 */
  renderDatePicker({ key, label, disabled }) {
    const { requiredFields, mode } = this.props;
    const { form, missingFields, invalidFields } = this.state;
    const isDisabled = disabled || false;
    let isRequired = Array.isArray(requiredFields) ? requiredFields.includes(key) : false;
    if (requiredFields[mode]) {
      isRequired = requiredFields[mode].includes(key);
    }
    const valueDate = form[key] ? moment(form[key]) : '';
    return (
      <DesktopDatePicker
        key={key}
        value={valueDate}
        label={label || key}
        disabled={isDisabled}
        onChange={this.handleChangeDatePicker(key)}
        required={isRequired}
        slotProps={{
          textField: {
            error: missingFields.includes(key) || invalidFields.includes(key),
            inputProps: {
              placeholder: 'Choisir une date',
            }
          },
        }}
      />
    );
  }

  /**
* TimePicker générique
* @param {object} param* objet de paramètres
* @param {string} param.key* la clé/colonne de l'item
* @param {string} param.label le label a afficher dans le textfield
* @param {bool} param.disabled désactive le champs
* @returns {Node} le noeud react du textfield
*/
  renderTimePicker({ key, label, disabled }) {
    const { requiredFields, mode } = this.props;
    const { form, missingFields, invalidFields } = this.state;
    const isDisabled = disabled || false;
    let isRequired = Array.isArray(requiredFields) ? requiredFields.includes(key) : false;
    if (requiredFields[mode]) {
      isRequired = requiredFields[mode].includes(key);
    }
    const valueTime = form[key] ? moment(form[key], 'HH:mm') : '';
    return (
      <TimePicker
        key={key}
        value={valueTime}
        label={label || key}
        disabled={isDisabled}
        onChange={this.handleChangeTimePicker(key)}
        required={isRequired}
        slotProps={{
          textField: {
            error: missingFields.includes(key) || invalidFields.includes(key),
            inputProps: {
              placeholder: 'Choisir une heure',
            }
          },
        }}
      />
    );
  }


  /**
   * Select générique
   * @param {object} param* objet de paramètres
   * @param {string} param.key* la clé/colonne de l'item
   * @param {string} param.label le label a afficher du select
   * @param {array} param.options* liste des options du select
   * @param {func} param.keyGetter  
   * @param {func} param.valueGetter  
   * @param {func} param.labelGetter 
   * @param {bool} param.multiple permet plusieurs valeurs
   * @param {bool} param.disabled désactive le champs
   * @returns {Node} le noeud react du select
   */
  renderSelect({ key, label, placeholder, options, getOptionLabel, loading = false, helperText, isOptionEqualToValue, disabled, multiple, onInputChange, filterOptions, BoxProps }) {
    const { requiredFields, mode } = this.props;
    const { form, missingFields, invalidFields } = this.state;
    const isDisabled = disabled || false;
    const isMultiple = multiple || false;
    let isRequired = Array.isArray(requiredFields) ? requiredFields.includes(key) : false;
    if (requiredFields[mode]) {
      isRequired = requiredFields[mode].includes(key);
    }
    return (
      <AutoSelect
        BoxProps={BoxProps}
        name={key}
        label={label || key}
        placeholder={placeholder}
        options={options}
        value={form[key]}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={isOptionEqualToValue}
        disabled={isDisabled}
        multiple={isMultiple}
        required={isRequired}
        error={missingFields.includes(key) || invalidFields.includes(key)}
        onChange={value => this.handleChangeSelect(key, value)}
        onInputChange={onInputChange}
        loading={loading}
        helperText={helperText || null}
        filterOptions={filterOptions}
      />
    );
  }

  renderContent() { return null; } // Override
  render() {
    const { item, rowKey, mode, working, onCancel } = this.props;
    const { missingFields } = this.state;
    const itemId = EDITMODAL_MODES.EDIT ? item[rowKey] : null;
    return (
      <>
        <DialogTitle>
          {mode === EDITMODAL_MODES.EDIT ? `Edition de ${rowKey} ${!isNaN(itemId) ? '#' : ''}${itemId}` : 'Ajout d\'un élément'}
        </DialogTitle>
        <Divider />
        <DialogContent>
          {this.renderContent()}
          {working && <LinearProgress sx={{ marginTop: 2 }} />}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button variant="outlined" color="error" onClick={onCancel} disabled={working}>Annuler</Button>
          <Button
            variant="contained"
            color="primary"
            disabled={missingFields.length > 0 || working}
            onClick={this.handleValidate}
          >
            Valider
          </Button>
        </DialogActions>
      </>
    );
  }
}

EditModalWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  // Main
  item: PropTypes.object,
  rowKey: PropTypes.string,
  mode: PropTypes.oneOf([EDITMODAL_MODES.EDIT, EDITMODAL_MODES.ADD]),
  working: PropTypes.bool,
  requiredFields: PropTypes.oneOfType([PropTypes.array, PropTypes.object]), // Either ['fieldname'] or { [EDITMODAL_MODES.ADD]: ['fieldname], [EDITMODAL_MODES.EDIT]: ['fieldname']}
  // Func
  onValidate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default EditModalWrapper;

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import isEmpty from 'lodash/isEmpty';
import Config from '../../config';

import styles from './styles.js';

class NotFound extends React.Component {
  render() {
    const { classes, user } = this.props;
    const { LOGGED_REDIRECTION, UNLOGGED_REDIRECTION } = Config;
    const url = !user.actions || isEmpty(user.actions) ? UNLOGGED_REDIRECTION : LOGGED_REDIRECTION;
    return (
      <div className={classes.wrapper}>
        <Stack className={classes.content}>
          <Typography variant="h1">404</Typography>
          <Typography variant="h3" gutterBottom>Cette page n'existe pas</Typography>
          <div>
            <Button variant="outlined" className={classes.redirectButton}>
              <Link to={url}>Retour à l'application</Link>
            </Button>
          </div>
        </Stack>
      </div>
    );
  }
}

NotFound.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object,
}

export default withStyles(styles)(NotFound);

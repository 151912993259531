import React from 'react';
import Item from './Item';

export function GetChild(key, content, props) {
  return (
    <Item
      className={props.itemClassName}
      xs={props.xs}
      sm={props.sm}
      md={props.md}
      lg={props.lg}
      xl={props.xl}
      key={key}
    >
      {content}
    </Item>
  );
}
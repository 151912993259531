import React from 'react';
import moment from 'moment';
import { green, red } from '@mui/material/colors';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import EditableList from '../../containers/EditableList';
import styles from './styles.js';
class Profit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      refreshToken: 0,
    };
  }

  renderMargeCell({ row }) {
    const { real_marge } = row;
    const intlNumber = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' });
    return (
      <b style={{ color: real_marge > 0 ? green[800] : red[800] }}>
        {intlNumber.format(real_marge)}
      </b>
    );
  }

  render() {
    const { classes, user, shop } = this.props;
    const { refreshToken } = this.state;
    const intlNumber = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' });
    return (
      <div className={classes.wrapper}>
        <EditableList
          refreshToken={refreshToken}
          title="Rentabilité"
          route="events-profit"
          rowKey="id"
          defaultParams={{ event_date_start: moment().subtract(1, 'month').startOf('month').format("YYYY-MM-DD"), shop_id: shop.id }}
          queryStringEnrich={() => ({ closed: '1', deleted: '0' })}
          maxSize="xl"
          columns={[
            { field: 'id', headerName: 'ID', width: 90 },
            { field: 'shop_id', headerName: 'Boutique', width: 90, sortable: false, valueFormatter: ({ value }) => user.shops.find(shop => parseInt(shop.id, 10) === value)?.name || '' },
            { field: 'name', headerName: 'Nom', width: 200 },
            { field: 'event_date', headerName: 'Date', width: 120, valueFormatter: ({ value }) => moment(value).format("DD-MM-YYYY") },
            { field: 'price', headerName: 'Prix', width: 80, sortable: false, valueFormatter: ({ value }) => intlNumber.format(value) },
            { field: 'quantity', headerName: 'Places', width: 90, sortable: false },
            { field: 'real_quantity', headerName: 'Participants', width: 110, sortable: false },
            { field: 'expected_cost', headerName: 'Côut attendu', width: 120, sortable: false, valueFormatter: ({ value }) => intlNumber.format(value) },
            { field: 'real_cost', headerName: 'Côut réel', width: 120, sortable: false, valueFormatter: ({ value }) => intlNumber.format(value) },
            { field: 'expected_ca', headerName: 'CA attendu', width: 120, sortable: false, valueFormatter: ({ value }) => intlNumber.format(value) },
            { field: 'real_ca', headerName: 'CA réel', width: 100, sortable: false, valueFormatter: ({ value }) => intlNumber.format(value) },
            { field: 'expected_marge', headerName: 'Marge attendu', width: 130, sortable: false, valueFormatter: ({ value }) => intlNumber.format(value) },
            { field: 'real_marge', headerName: 'Marge réelle', width: 150, sortable: false, renderCell: this.renderMargeCell },
          ]}
          filterParams={[
            { key: 'id', label: 'ID' },
            { key: 'event_date_start', label: 'Date de début', type: 'date' },
            { key: 'event_date_end', label: 'Date de fin', type: 'date' },
            { key: 'shop_id', label: 'Boutiques', type: 'select', options: user.shops.map(item => parseInt(item.id, 10)), getOptionLabel: opt => user.shops.find(shop => parseInt(shop.id, 10) === opt)?.name || '', variant: 'standard' },
          ]}
          searchPlaceholder="Filter par Nom"
          allowEdit={false}
          allowAdd={false}
          allowDelete={false}
        />
      </div>
    );
  }
}

Profit.propTypes = {
  classes: PropTypes.object.isRequired,
  redirect: PropTypes.func.isRequired,
  match: PropTypes.object,
  user: PropTypes.object,
}

export default withStyles(styles)(Profit);

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import styles from './styles.js';
import EditableList from '../../containers/EditableList';
import EditModal from './EditModal';
import { EDITMODAL_MODES } from '../../containers/EditableList/constants';
import moment from 'moment';

class Participant extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      statePayment: { 'wait': 'En attente', 'cancel': 'Annulé', 'paid': 'Payé' },
      typePayment: { 'cb': 'CB', 'cash': 'Liquide', 'other': 'Autre' },
      intlNumber: new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }),
    };
  }
  render() {
    const { classes, event_id, event_name, event_price, refreshEvent, shop, user } = this.props;
    const { statePayment, typePayment, intlNumber } = this.state;
    return (
      <div className={classes.wrapper}>
        <EditableList
          onApplyChange={refreshEvent}
          title="Participants"
          route="participants"
          rowKey="id"
          defaultParams={{ shop_id: !event_id ? shop.id : null, state: 'paid' }}
          disabledHashedState={!!event_id}
          queryStringEnrich={() => ( {event_id} )}
          columns={[
            { field: 'id', headerName: 'ID', width: 90 },
            { field: 'shop_id', headerName: 'Boutique', width: 90, sortable: false, valueFormatter: ({ value }) => user.shops.find(shop => parseInt(shop.id, 10) === value)?.name || '' },
            { field: 'parkage_user_id', headerName: 'ParkageID', width: 100 },
            { field: 'lastname', headerName: 'Nom', width: 100 },
            { field: 'firstname', headerName: 'Prénom', width: 100 },
            { field: 'username', headerName: 'Pseudo', width: 100 },
            { field: 'email', headerName: 'Email', width: 200 },
            { field: 'price', headerName: 'Montant à régler', width: 150, valueFormatter: ({ value }) => intlNumber.format(value) || value },
            { field: 'state', headerName: 'État', width: 120, valueFormatter: ({ value }) => statePayment[value] || '' },
            { field: 'payment_type', headerName: 'Type du payement', width: 120, valueFormatter: ({ value }) => typePayment[value] || '' },
            { field: 'event_name', headerName: 'Évènement', width: 250, sortable: false },
            { field: 'identifier', headerName: 'Matricule', width: 250 },
            { field: 'register_date', headerName: 'Enregistré le', width: 180, valueFormatter: ({ value }) => moment.utc(value, "YYYY-MM-DD HH:mm:ss").local().format("YYYY-MM-DD HH:mm:ss") },
          ]}
          filterParams={[
            { key: 'id', label: 'ID' },
            { key: 'lastname', label: 'Nom' },
            { key: 'firstname', label: 'Prénom' },
            { key: 'username', label: 'Pseudonyme' },
            { key: 'email', label: 'Email' },
            { key: 'event_name', label: 'Évènement' },
            { key: 'state', label: 'État', type: 'select', options: ['', 'wait', 'cancel', 'paid'], getOptionLabel: opt => statePayment[opt] || 'Tout'},
            { key: 'shop_id', label: 'Boutiques', type: 'select', options: user.shops.map(item => parseInt(item.id, 10)), getOptionLabel: opt => user.shops.find(shop => parseInt(shop.id, 10) === opt)?.name || '', variant: 'standard' },
          ]}
          requiredFields={{
            [EDITMODAL_MODES.ADD]: ['email', 'event_id'],
            [EDITMODAL_MODES.EDIT]: ['email', 'event_id']
          }}
          EditModalComponent={EditModal}
          searchPlaceholder="Filter par Nom, Prénom, Pseudo, Email"
          options={{
            statePayment,
            typePayment,
            event_id,
            event_name,
            event_price,
          }}
          // only superadmin can delete
          allowDelete={!!user.groups.find(group => parseInt(group.id) === 1)}
          // allowEdit={rights.includes('write')}
          // allowAdd={rights.includes('write')}
        />
      </div>
    );
  }
}

Participant.propTypes = {
  classes: PropTypes.object.isRequired,
  redirect: PropTypes.func,
  match: PropTypes.object,
  user: PropTypes.object,
  event_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default withStyles(styles)(Participant);

import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import { isNil } from "lodash";
import {
	IconButton,
	Tooltip,
	DialogContentText,
	TextField,
	Box,
} from "@mui/material";
import OutboxIcon from "@mui/icons-material/Outbox";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import WarningIcon from "@mui/icons-material/GppMaybeOutlined";
import styles from "./styles.js";
import EditableList from "../../containers/EditableList";
import EditModal from "./EditModal";
import { EDITMODAL_MODES } from "../../containers/EditableList/constants";
import Confirm from "../../components/base/Confirm";
import Notify from "../../components/base/Notify";
import Ajax from "../../utils/Ajax";

class Endowment extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			stateEndowment: {
				wait: "En attente",
				nodispatch: "Non distribuée",
				dispatch: "Distribuée",
			},
			intlNumber: new Intl.NumberFormat("fr-FR", {
				style: "currency",
				currency: "EUR",
			}),
			destockItem: null,
			destockValue: null,
			refreshToken: 0,
		};
	}

	async onDestock(item, newStock, destock) {
		const { refreshEvent } = this.props;
		const newDestock = parseInt(destock || 0, 10);
		if (Number.isNaN(newDestock) || newDestock < 0) {
			Notify.error(`Valeur "${destock}" invalide`);
			return;
		}
		const { shop_id, event_id, event_name, product_id } = item;
		const result = await Ajax.patch({
			url: "api/endowments-stock",
			token: true,
			body: {
				id: item.id,
				shopId: shop_id,
				productId: product_id,
				eventId: event_id,
				eventName: event_name,
				quantity: newStock,
				destock: newDestock,
				diffQuantity: newStock - item.stock,
			},
		});
		if (result.type === "error") {
			Notify.error(result.message);
		} else {
			Notify.success("Modification du stock effectuée avec succès !");
			this.setState(
				(prev) => ({
					destockItem: null,
					destockValue: null,
					refreshToken: prev.refreshToken + 1,
				}),
				() => {
					if (refreshEvent) {
						refreshEvent();
					}
				}
			);
		}
	}

	renderDestock() {
		const { destockItem, destockValue } = this.state;
		if (!destockItem) {
			return null;
		}
		const defaultValue = parseInt(
			destockItem.destock > 0 ? destockItem.destock : destockItem.quantity,
			10
		);
		const value = isNil(destockValue) ? defaultValue : destockValue;
		const originalStock = parseInt(destockItem.stock + destockItem.destock, 10);
		const newStock = Number.isNaN(parseInt(value || 0))
			? "Error"
			: originalStock - value;
		return (
			<Confirm
				open
				dialogProps={{
					sx: {
						"& .MuiDialog-container": {
							"& .MuiPaper-root": {
								minWidth: "550px",
							},
						},
					},
				}}
				title="Modification de la quantitée déstockée"
				message={
					<>
						<Box sx={{ marginTop: 1 }}>
							<Box
								sx={{
									paddingBottom: 1,
									display: "flex",
									alignItems: "flex-end",
								}}
							>
								<DialogContentText sx={{ paddingRight: 1, paddingBottom: 0.6 }}>
									Quantité total déstockée :{" "}
								</DialogContentText>
								<TextField
									sx={{ paddingRight: 1, width: 50 }}
									value={value}
									variant="standard"
									size="small"
									onChange={(evt) =>
										this.setState({ destockValue: evt.target.value })
									}
								/>
								<DialogContentText sx={{ paddingRight: 1, paddingBottom: 0.6 }}>
									sur {destockItem.quantity} de prévue
								</DialogContentText>
							</Box>
							<DialogContentText>
								Passer le stock boutique de {destockItem.stock} à {newStock} ?
							</DialogContentText>
						</Box>
						{newStock < 0 ? (
							<DialogContentText color="error" sx={{ marginTop: 1 }}>
								<WarningIcon sx={{ verticalAlign: "middle" }} /> Attention, le
								nouveau stock boutique est une valeur négative !
							</DialogContentText>
						) : null}
					</>
				}
				onClickAway={() =>
					this.setState({ destockItem: null, destockValue: null })
				}
				onValidate={() => this.onDestock(destockItem, newStock, value)}
			/>
		);
	}

	render() {
		const { classes, event_id, event_closed, event_name, shop, refreshEvent, user } = this.props;

		const { stateEndowment, intlNumber, refreshToken } = this.state;
		return (
			<div className={classes.wrapper}>
				{this.renderDestock()}
				<EditableList
					onApplyChange={refreshEvent}
					refreshToken={refreshToken}
					title="Dotations"
					route="endowments"
					rowKey="id"
					defaultParams={{ shop_id: !event_id ? shop.id : null }}
					disabledHashedState={!!event_id}
					queryStringEnrich={() => ({ event_id })}
					columns={[
						{
							field: "__view",
							headerName: "",
							width: 60,
							sortable: false,
							renderCell: ({ row }) => (
								<Tooltip title="Destokage de la dotation">
									<span>
										<IconButton
											disabled={!!row.dispensed}
											color={row.destock > 0 ? "" : "error"}
											onClick={() => this.setState({ destockItem: row })}
										>
											{row.destock > 0 ? <MoveToInboxIcon /> : <OutboxIcon />}
										</IconButton>
									</span>
								</Tooltip>
							),
						},
						{ field: "id", headerName: "ID", width: 80 },
						{
							field: "product_name",
							headerName: "Produit",
							width: 300,
							sortable: false,
						},
						{
							field: "stock",
							headerName: "Stock boutique",
							width: 130,
							sortable: false,
						},
						{
							field: "shop_id",
							headerName: "Boutique",
							width: 90,
							sortable: false,
							valueFormatter: ({ value }) =>
								user.shops.find((shop) => parseInt(shop.id, 10) === value)
									?.name || "",
						},
						{ field: "quantity", headerName: "Quantité prévue", width: 150 },
						{ field: "destock", headerName: "Quantité destockée", width: 150 },
						{
							field: "dispensed",
							headerName: "Distribuée",
							width: 90,
							sortable: false,
							valueFormatter: ({ value }) => (value === 1 ? "Oui" : "Non"),
						},
						{
							field: "price_unit",
							headerName: "Prix unitaire d'achat",
							width: 180,
							valueFormatter: ({ value }) => intlNumber.format(value) || value,
						},
						{
							field: "total_price",
							headerName: "Coût prévue",
							width: 120,
							valueFormatter: ({ value }) => intlNumber.format(value) || value,
						},
						{
							field: "real_total_price",
							headerName: "Coût effectif",
							width: 120,
							valueFormatter: ({ value }) => intlNumber.format(value) || value,
						},
						{
							field: "dispatch_comment",
							headerName: "Commentaire",
							width: 400,
						},
					]}
					filterParams={[
						{ key: "id", label: "ID" },
						{ key: "event_name", label: "Évènement" },
						{
							key: "shop_id",
							label: "Boutiques",
							type: "select",
							options: user.shops.map((item) => parseInt(item.id, 10)),
							getOptionLabel: (opt) =>
								user.shops.find((shop) => parseInt(shop.id, 10) === opt)
									?.name || "",
							variant: "standard",
						},
					]}
					requiredFields={{
						[EDITMODAL_MODES.ADD]: ["event_id", "product_id"],
						[EDITMODAL_MODES.EDIT]: ["event_id", "product_id"],
					}}
					EditModalComponent={EditModal}
					searchPlaceholder="Filter nom de produit, code barre, taxonomies "
					options={{
						stateEndowment,
						event_id,
						event_name,
						userDefaultShop: shop,
					}}
          allowEdit={!event_closed}
          allowAdd={!event_closed}
          allowDelete={!event_closed}
				/>
			</div>
		);
	}
}

Endowment.propTypes = {
	classes: PropTypes.object.isRequired,
	redirect: PropTypes.func,
	match: PropTypes.object,
	event_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default withStyles(styles)(Endowment);

import React from 'react';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import EmojiEventsdIcon from '@mui/icons-material/EmojiEvents';
import AppRegistrationIcon from '@mui/icons-material/AppRegistrationOutlined';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import DiamondIcon from '@mui/icons-material/Diamond';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import PermMediaOutlinedIcon from '@mui/icons-material/PermMediaOutlined';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import NotFound from '../../pages/NotFound';
import Login from '../../pages/Login';
import Dashboard from '../../pages/Dashboard';
import Event from '../../pages/Event';
import EventView from '../../pages/Event/view';
import Format from '../../pages/Format';
import Registration from '../../pages/Registration';
import Endowment from '../../pages/Endowment';
import Participant from '../../pages/Participant';
import Gallery from '../../pages/Gallery';
import Profit from '../../pages/Profit';

/**
 * Node : le Component
 * route : routePath
 * exact : route exact => true / false
 * action : action => action requise pour afficher la page
 * label : Nom de la page dans l'onglet du navigateur
 */
const ConfigPage = {
  Home: { Node: Login, route: '/', exact: true },
  NotFound: { Node: NotFound, route: null, exact: false },
  Login: { Node: Login, route: '/login', exact: true },
  Dashboard: {
    Node: Dashboard, 
    route: '/dashboard',
    exact: true,
    action: 'backoffice',
    appMenu: true,
    showInMenu: true,
    label: 'Tableau de bord',
    icon: <DashboardOutlinedIcon />,
  },
  EventView: {
    Node: EventView,
    route: '/event/view/:id(\\d+)',
    exact: true,
    action: 'backoffice',
    appMenu: true,
    showInMenu: false,
    label: 'Évènements',
    icon: <EmojiEventsdIcon />,
  },
  Profit: {
    Node: Profit,
    route: '/profit',
    exact: true,
    action: 'backoffice',
    appMenu: true,
    showInMenu: true,
    label: 'Rentabilité',
    icon: <QueryStatsIcon />,
  },
  Event: {
    Node: Event,
    route: '/event',
    exact: true,
    action: 'backoffice',
    appMenu: true,
    showInMenu: true,
    label: 'Évènements',
    icon: <EmojiEventsdIcon />,
  },
  Format: {
    Node: Format,
    route: '/format',
    exact: true,
    action: 'backoffice',
    appMenu: true,
    showInMenu: true,
    label: 'Formats',
    icon: <AppRegistrationIcon />,
  },
  Registration: {
    Node: Registration,
    route: '/registration',
    exact: true,
    action: 'backoffice',
    appMenu: true,
    showInMenu: true,
    label: 'Immatriculations',
    icon: <ContactEmergencyIcon />,
  },
  Endowment: {
    Node: Endowment,
    route: '/endowment',
    exact: true,
    action: 'backoffice',
    appMenu: true,
    showInMenu: true,
    label: 'Dotations',
    icon: <DiamondIcon />,
  },
  Participant: {
    Node: Participant,
    route: '/participant',
    exact: true,
    action: 'backoffice',
    appMenu: true,
    showInMenu: true,
    label: 'Participants',
    icon: <SupervisorAccountOutlinedIcon />,
  },
  Gallery: {
    Node: Gallery,
    route: '/gallery',
    exact: true,
    action: 'backoffice',
    appMenu: true,
    showInMenu: true,
    label: 'Médias',
    icon: <PermMediaOutlinedIcon />,
  },
};

export function getPage(pageName) {
  if (ConfigPage[pageName]) {
    return ConfigPage[pageName];
  }
  return null;
}

export default ConfigPage;